import {
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  FormControlLabel,
  FormControlLabelProps,
  StyleProps,
} from '@mui/material'
import SwitchCheck from './switch-checked.svg?raw'
import SwitchUncheck from './switch-unchecked.svg?raw'

export type SwitchProps = Omit<
  MuiSwitchProps,
  | 'action'
  | 'classes'
  | 'checkedIcon'
  | 'disableRipple'
  | 'disableFocusRipple'
  | 'disableTouchRipple'
  | 'edge'
  | 'focusRipple'
  | 'focusVisibleClassName'
  | 'icon'
  | 'inputProps'
  | 'LinkComponent'
  | 'sx'
  | 'style'
  | 'TouchRippleProps'
  | 'touchRippleRef'
  | 'type'
  | 'value'
> & {
  label?: string
  labelPlacement?: FormControlLabelProps['labelPlacement']
}

const styles: StyleProps = {
  switch: {
    height: 20,
    width: 40,
    p: 0,
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      height: 16,
      width: 16,
      bgcolor: 'N000',
      border: 'none',
    },
    '& .MuiSwitch-track': {
      bgcolor: 'N200',
      borderRadius: 5,
      opacity: 1,
      '&:before, &:after': {
        content: '""',
        position: 'absolute',
        transform: 'translateY(calc(50% - 2px))',
        width: 12,
        height: 12,
        color: 'N000',
        backgroundRepeat: 'no-repeat',
      },
      '&:before': {
        backgroundImage: `url('data:image/svg+xml;utf8,${SwitchCheck.trim()}')`,
        left: 4,
      },
      '&:after': {
        backgroundImage: `url('data:image/svg+xml;utf8,${SwitchUncheck.trim()}')`,
        right: 4,
      },
    },
    '&.MuiSwitch-sizeSmall': {
      height: 16,
      width: 32,
      '& .MuiSwitch-thumb': {
        height: 12,
        width: 12,
      },
      '& .MuiSwitch-track': {
        '&:before, &:after': {
          transform: 'translateY(calc(50% - 4px))',
        },
        '&:before': {
          left: 2,
        },
        '&:after': {
          right: 2,
        },
      },
    },
    '& .MuiSwitch-switchBase': {
      p: 0,
      top: 2,
      left: 2,
      bgcolor: 'N000',
      '&.Mui-checked': {
        '+.MuiSwitch-track': {
          opacity: 1,
          bgcolor: 'G300',
        },
        '&.MuiSwitch-colorError+.MuiSwitch-track': {
          bgcolor: 'R400',
        },
      },
      '&.Mui-disabled+.MuiSwitch-track': {
        opacity: 0.5,
      },
    },
  },
  label: {
    m: 0,
    '& .MuiTypography-root': {
      fontSize: 14,
    },
    '&.MuiFormControlLabel-labelPlacementStart': {
      '& .MuiTypography-root': {
        fontSize: 14,
        mr: 2,
      },
    },
    '&.MuiFormControlLabel-labelPlacementEnd': {
      '& .MuiTypography-root': {
        ml: 2,
      },
    },
  },
}

export const Switch = ({ label, labelPlacement, ...props }: SwitchProps) => {
  return (
    <FormControlLabel
      sx={styles.label}
      label={label}
      control={<MuiSwitch {...props} sx={styles.switch} />}
      labelPlacement={labelPlacement}
    />
  )
}
