import { useState } from 'react'
import { Box, StyleProps, useTheme } from '@mui/material'
import Typography from '@mui/material/Typography'
import { Bar, BarChart, Cell, Tooltip, TooltipProps } from 'recharts'
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent'
import { CategoricalChartState } from 'recharts/types/chart/types'

const BAR_WIDTH_PX = 20
const BAR_GAP_PX = 4

export type HistogramData = {
  name: string
  value: number
}

export type HistogramProps = {
  data: HistogramData[]
}

const HistogramTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const {
      payload: { name },
      value,
    } = payload[0]

    return (
      <Box role="tooltip">
        <Typography color="N000">{name}</Typography>
        <Typography color="N000">Items: {value}</Typography>
      </Box>
    )
  }

  return null
}

const styles: StyleProps = {
  root: {
    height: '100%',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      height: 10,
      background: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      bgcolor: 'N040',
      borderRadius: 2,
    },
  },
}

export const Histogram = ({ data }: HistogramProps) => {
  const theme = useTheme()
  const width = data.length * (BAR_WIDTH_PX + BAR_GAP_PX)
  const [focusBar, setFocusBar] = useState<number | null>(null)

  const onMouseMove = (state: CategoricalChartState) => {
    if (state.isTooltipActive) {
      setFocusBar(state.activeTooltipIndex ?? null)
    } else {
      setFocusBar(null)
    }
  }

  return (
    <Box sx={styles.root} tabIndex={0}>
      <BarChart
        width={width}
        height={110}
        data={data}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        onMouseMove={onMouseMove}
      >
        <Tooltip
          wrapperStyle={{
            background: theme.palette.N800,
            padding: '4px 8px',
            borderRadius: '3px',
          }}
          cursor={false}
          content={<HistogramTooltip />}
        />
        <Bar dataKey="value" barSize={BAR_WIDTH_PX} background={{ fill: theme.palette.N030 }}>
          {data.map((_, index) => (
            <Cell
              key={`cell-${index}`}
              fill={focusBar === index ? theme.palette.G400 : theme.palette.G200}
            />
          ))}
        </Bar>
      </BarChart>
    </Box>
  )
}
