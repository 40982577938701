import { Box, BoxProps } from '@mui/material'
import CatalogLogo from '@assets/images/logo-catalog.svg'

export type LogoProps = Pick<BoxProps, 'width' | 'height'> & {
  alt: string
}

export const Logo = (props: LogoProps) => {
  const { width, height = '100%', alt = 'Catalog logo' } = props
  return <Box component="img" width={width} height={height} alt={alt} src={CatalogLogo} />
}
