import { useMemo } from 'react'
import { ConfigureStoreOptions, configureStore, combineReducers } from '@reduxjs/toolkit'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import mainSlice from '@containers/main/main-slice'
import reviewSlice from '@pages/review/review-slice'
import reviewDetailSlice from '@pages/review-detail/review-detail-slice'

export const reducer = combineReducers({
  main: mainSlice,
  review: reviewSlice,
  reviewDetail: reviewDetailSlice,
})

export function createStore({
  history,
  preloadedState,
}: {
  history?: NavigateFunction
  preloadedState?: ConfigureStoreOptions['preloadedState']
}) {
  return configureStore({
    reducer,
    devTools: true,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: {
          extraArgument: { history },
        },
        serializableCheck: false,
      }),
  })
}

export function useStore() {
  const history = useNavigate()

  // never should rebuild the store, so empty array
  return useMemo(() => createStore({ history }), [])
}

export type AppDispatch = ReturnType<typeof createStore>['dispatch']
export type RootState = ReturnType<ReturnType<typeof createStore>['getState']>
export type AppStore = ReturnType<typeof createStore>
export const useAppDispatch: () => AppDispatch = useDispatch
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
