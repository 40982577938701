import ClearIcon from '@mui/icons-material/Clear'
import { IconButton, Chip as MuiChip, ChipProps as MuiChipProps, StyleProps } from '@mui/material'

import { CustomPalette } from '@core/styles/theme'
import { Skeleton } from '@components/skeleton'

export type ChipProps = Omit<
  MuiChipProps,
  | 'variant'
  | 'component'
  | 'classes'
  | 'sx'
  | 'deleteIcon'
  | 'style'
  | 'avatar'
  | 'clickable'
  | 'icon'
  | 'skipFocusWhenDisabled'
> & {
  bgcolor?: keyof CustomPalette | string
  textColor?: keyof CustomPalette | string
  focused?: boolean
  maxWidth?: number
}

const styles: StyleProps = {
  root: {
    borderRadius: '3px',
    height: 'auto',
    fontSize: 14,
    fontWeight: 500,
    transition: 'box-shadow 0.3s ease',
    '&.MuiChip-sizeMedium': {
      height: 24,
      '&.MuiChip-deletable': {
        '.MuiChip-label': { pr: 0 },
      },
      '.MuiChip-label': { px: 1, py: 0.5 },
    },
    '&.MuiChip-sizeSmall': {
      height: 20,
      '&.MuiChip-deletable': {
        '.MuiChip-label': { pr: 0 },
      },
      '.MuiChip-label': { p: '3.3px 6.7px 3.3px 6.7px', fontSize: 11.7 },
      '.MuiChip-deleteIcon': {
        fontSize: 20,
      },
    },
    '&:focus': {
      outline: (theme) => `2px solid ${theme.palette.B100}`,
    },
    '&.Mui-disabled': {
      opacity: 0.5,
      bgcolor: 'N500',
      color: 'N000',
    },
    '.MuiChip-label': { p: 0 },
    '.MuiChip-deleteIcon': {
      m: 0,
      fontSize: 24,
      fontWeight: 600,
      p: 0.5,
      color: 'N500',
      borderRadius: '0 5px 5px 0',
    },
    '&.deleteIcon': {
      flexDirection: 'row-reverse',
      span: {
        pr: '2px !important',
      },
    },
  },
  deleteIcon: {
    width: '20px !important',
    height: '20px !important',
    m: '0px !important',
  },
}

export const Chip = (props: ChipProps) => {
  const { bgcolor, textColor, focused, maxWidth, onDelete, ...rest } = props

  return (
    <>
      <MuiChip
        {...rest}
        sx={{
          ...styles.root,
          ...(textColor && {
            color: textColor,
          }),
          ...(bgcolor && {
            bgcolor,
            '&:hover': {
              bgcolor,
            },
          }),
          maxWidth,
          ...(focused && {
            outline: (theme) => `2px solid ${theme.palette.B100}`,
          }),
        }}
        {...(onDelete && {
          className: 'deleteIcon',
        })}
        icon={
          onDelete && (
            <IconButton onClick={onDelete} size="small" sx={styles.deleteIcon}>
              <ClearIcon sx={{ fontSize: 14 }} fontSize="small" />
            </IconButton>
          )
        }
      />
    </>
  )
}

export const ChipSkeleton = () => {
  return <Skeleton width={48} height={28} variant="rounded" />
}
