import React, { useEffect } from 'react'
import {
  ClickAwayListener,
  StyleProps,
  alpha,
  Typography,
  Popper,
  IconButton,
  type TypographyProps,
} from '@mui/material'
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/solid'
import AttachFileRoundedIcon from '@mui/icons-material/AttachFileRounded'

const styles: StyleProps = {
  container: {
    '.highlight': {
      bgcolor: 'Y200',
    },
    width: '100%',
    userSelect: 'text',
  },
  evidence: {
    boxShadow: (theme) =>
      `0 9px 17px -4px ${alpha(theme.palette.N900, 0.25)}, 0 0 1px 0 ${alpha(
        theme.palette.N900,
        0.31
      )}`,
    bgcolor: 'N000',
    p: 0.5,
    '&:hover': {
      bgcolor: 'N000',
    },
  },
  popover: {
    '.MuiPopover-paper': {
      '&:hover': {
        bgcolor: 'N000',
      },
      borderRadius: '50%',
      bgcolor: 'N000',
    },
  },
}

export type HighlightTextField = {
  start: number
  end: number
  text?: string
}

export type Source = {
  origin: string
  offsets: HighlightTextField[]
}

export type HighlightTextProps = {
  text: string | null
  origin: string
  onSelectionChange: (source: Source) => void
  offsets?: HighlightTextField[]
  onComment?: () => void
  showComment?: boolean
  disablePopper?: boolean
}

export const HighlightText = ({
  text,
  origin,
  onSelectionChange,
  offsets = [],
  onComment,
  showComment,
  disablePopper,
  ...props
}: HighlightTextProps & TypographyProps) => {
  const [open, setOpen] = React.useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const refText = React.useRef<HTMLDivElement>(null)

  useEffect(() => {
    if ((open || showComment) && !anchorEl) {
      setAnchorEl(refText.current)
    }
  }, [open, showComment])

  const highlightOffsets = () => {
    if (!offsets.length || !text) return text

    const result = []
    let currentIndex = 0
    const sortedOffsets = Array(...offsets).sort((a, b) => a?.start - b?.start)
    sortedOffsets.forEach(({ start, end }, index) => {
      if (currentIndex < start) {
        result.push(
          <span key={index + 'normal'} data-start={currentIndex} data-index-end={start}>
            {text.slice(currentIndex, start)}
          </span>
        )
      }

      result.push(
        <mark
          key={index + 'highlight'}
          role="mark"
          className="highlight"
          data-index-start={start}
          data-index-end={end}
        >
          {text.slice(start, end)}
        </mark>
      )
      currentIndex = end
    })

    if (currentIndex < text.length) {
      result.push(
        <span data-index-start={currentIndex} data-index-end={text.length}>
          {text.slice(currentIndex, text.length)}
        </span>
      )
    }

    return result
  }

  const handleClosePopOver = () => {
    setOpen(false)
  }

  const getRangeSelection = (): {
    start: number
    end: number
    hasSelection: boolean
  } => {
    const selection = window.getSelection()
    let start = 0
    let end = 0
    let hasSelection = false
    if (selection && selection.rangeCount > 0) {
      // get span before element
      const parentFocus = parseInt(
        selection.focusNode?.parentElement?.getAttribute('data-index-start') ?? '0'
      )
      const parentAnchor = parseInt(
        selection.anchorNode?.parentElement?.getAttribute('data-index-start') ?? '0'
      )

      start =
        selection.anchorOffset < selection.focusOffset
          ? selection.anchorOffset + parentAnchor
          : selection.focusOffset + parentFocus
      end =
        selection.anchorOffset < selection.focusOffset
          ? selection.focusOffset + parentFocus
          : selection.anchorOffset + parentAnchor
      hasSelection = true
    }
    return { start, end, hasSelection }
  }

  const checkOverlapping = () => {
    const { start, end, hasSelection } = getRangeSelection()
    if (!hasSelection) return false

    const isOverleaping = offsets.some((offset) => {
      const { start: startOffset, end: endOffset } = offset
      return (
        (startOffset <= start && endOffset >= start) ||
        (startOffset <= end && endOffset >= end) ||
        (start <= startOffset && endOffset <= end)
      )
    })

    return isOverleaping
  }

  const handleTextSelection = () => {
    const { start, end, hasSelection } = getRangeSelection()

    if (!hasSelection) return

    onSelectionChange({
      offsets: [...offsets, { start, end, text: text?.slice(start, end) }],
      origin,
    })

    window.getSelection()?.empty()
  }

  const handlePopover = () => {
    const selection = window.getSelection()
    const { startOffset, endOffset } = selection?.getRangeAt(0) || { startOffset: 0, endOffset: 0 }
    const isOverleaping = checkOverlapping()
    if (startOffset === endOffset || endOffset === 0 || isOverleaping) {
      setOpen(false)
      return
    }
    setOpen(true)
  }

  const content = React.useMemo(() => React.Children.toArray(highlightOffsets()), [offsets, text])

  const options = {
    comment: {
      title: 'Add comment',
      component: (
        <IconButton onClick={onComment} aria-label="comment" sx={styles.evidence}>
          <ChatBubbleOvalLeftEllipsisIcon width={24} height={24} />
        </IconButton>
      ),
    },
    evidence: {
      title: 'Add evidence',
      component: (
        <IconButton onClick={handleTextSelection} aria-label="evidence" sx={styles.evidence}>
          <AttachFileRoundedIcon />
        </IconButton>
      ),
    },
  }
  const { title, component: Component } = options[showComment ? 'comment' : 'evidence']
  return (
    <>
      <ClickAwayListener onClickAway={handleClosePopOver}>
        <Typography
          ref={refText}
          onMouseUp={handlePopover}
          onDoubleClick={handlePopover}
          id="highlight-container"
          sx={styles.container}
          {...props}
        >
          {content}
        </Typography>
      </ClickAwayListener>

      {!disablePopper && (open || showComment) && anchorEl && (
        <Popper open anchorEl={anchorEl} placement="right" title={title}>
          {Component}
        </Popper>
      )}
    </>
  )
}
