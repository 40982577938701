import { ComponentType } from 'react'
import { withAuthenticationRequired } from '@auth0/auth0-react'

type AuthenticationGuardProps = {
  component: ComponentType
}

export const AuthenticationGuard = ({ component }: AuthenticationGuardProps) => {
  const Component = withAuthenticationRequired(component)
  return <Component />
}
