import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import { Box, StyleProps } from '@mui/material'

import { Typography } from '@components/typography'
import { toOptionCode } from '@core/formatters/string'
import { memo } from 'react'
import { Skeleton } from '@components/skeleton'
import { EvidenceHighlightText } from '@containers/evidences/evidence-highlight-text'
import { UseEvidenceProps } from '@containers/evidences/use-evidence'
import { ItemTypeSchema } from '@core/api/generated'

const styles: StyleProps = {
  root: {
    width: '100%',
  },
  stimulus: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mb: 2,
  },
  stem: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    p: 0,
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: 2,
    width: '100%',
    px: 2,
    py: 1.5,
    '&.Item-optionKey': {
      bgcolor: 'G050',
    },
    '& .MuiSvgIcon-root': {
      ml: 'auto',
      color: 'N500',
    },
  },
}

export type ItemTypeProps = {
  content: ItemTypeSchema
  tagShortName?: string
  evidence?: UseEvidenceProps
  labels?: {
    stem?: string
    stimulus?: string
  }
}

export const Loading = () => (
  <>
    <Skeleton variant="rounded" height={26} />
    <Skeleton variant="rounded" height={130} />
    <Skeleton variant="rounded" height={35} />
    <Skeleton variant="rounded" height={35} />
    <Skeleton variant="rounded" height={35} />
    <Skeleton variant="rounded" height={35} />
  </>
)

export const Item = memo(({ content, tagShortName, evidence, labels }: ItemTypeProps) => {
  if (!content) return <Loading />

  return (
    <Box sx={styles.root}>
      {content.stimulus && (
        <Box sx={styles.stimulus}>
          <Typography variant="h4">{labels?.stimulus || 'Stimulus'}</Typography>
          <EvidenceHighlightText
            origin={`${'stimulus.'}${tagShortName}`}
            text={content.stimulus}
            fontWeight="normal"
            {...evidence}
          />
        </Box>
      )}

      <Box sx={styles.stem}>
        <Typography variant="h4">{labels?.stem || 'Stem'}</Typography>
        <EvidenceHighlightText
          origin={`${'stem.'}${tagShortName}`}
          text={content.stem}
          fontWeight="normal"
          {...evidence}
        />
      </Box>

      <Box sx={styles.options} component="ul">
        {content.options.map((option, index) => {
          const isKey = content.key?.includes(toOptionCode(index))
          const optionCode = toOptionCode(index).toUpperCase()
          return (
            <Box
              aria-current={isKey}
              component="li"
              key={option}
              sx={styles.option}
              className={`Item-option${isKey ? 'Key' : ''}`}
            >
              <Typography variant="body1" fontWeight="bold">
                {optionCode}.
              </Typography>
              <EvidenceHighlightText
                origin={`${'option.'}${optionCode}.${tagShortName}`}
                text={option}
                variant="body1"
                {...evidence}
              />

              {isKey && <CheckCircleOutlineRoundedIcon fontSize="small" />}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
})
