import { Typography, TypographyProps } from '@mui/material'
import { UseEvidenceProps } from './use-evidence'
import { HighlightText } from '@components/highlight-text'

export type EvidenceHighlightTextProps = {
  origin:
    | `${'stem.'}${string}`
    | `${'option.'}${string}.${string}`
    | `${'tag.'}${string}`
    | `${'stimulus.'}${string}`
    | `${'text.'}${string}`
  text: string | null
} & TypographyProps &
  Partial<UseEvidenceProps>

export const EvidenceHighlightText = ({
  origin,
  text,
  highlight,
  newEvidence,
  onNewEvidence,
  onComment,
  ...props
}: EvidenceHighlightTextProps) => {
  const newHighlightOffsets = newEvidence?.source.find((t) => t.origin == origin)
  const highlightSource = highlight?.source?.find((source) => source.origin == origin)
  const isSameOrigin = Boolean(highlightSource)

  if (highlight?.source?.length && !isSameOrigin) return <Typography {...props}>{text}</Typography>

  return (
    <HighlightText
      text={text}
      origin={origin}
      onSelectionChange={onNewEvidence!}
      onComment={onComment}
      showComment={isSameOrigin}
      offsets={highlightSource?.offsets || newHighlightOffsets?.offsets || []}
      {...props}
    />
  )
}
