import { Box, StyleProps } from '@mui/material'
import { Button, Logo, Typography } from '@components'
import { ReactNode } from 'react'

export type ErrorPageProps = {
  code: keyof typeof CodeMessages
  message: ReactNode
}

export const CodeMessages: Record<number, string> = {
  404: 'Not Found',
  403: 'Forbidden',
  418: "I'm a teapot",
  500: 'Internal Server Error',
}
const styles: StyleProps = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: 500,
  },
  logoContainer: {
    mb: 4.5,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    mb: 5,
  },
}

export const ErrorPage = ({ code, message }: ErrorPageProps) => {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.container}>
        <Box sx={styles.logoContainer}>
          <Logo alt="Catalog Logo" width={220} />
        </Box>

        <Box sx={styles.content}>
          <Typography variant="h2">
            {code} - {CodeMessages[code]}
          </Typography>
          <Typography color="N100">{message}</Typography>
        </Box>

        <Button href="/" variant="contained" color="primary">
          Go back home
        </Button>
      </Box>
    </Box>
  )
}
