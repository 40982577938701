/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  Timeline as MuiTimeline,
  TimelineProps as MuiTimelineProps,
  TimelineItem as MuiTimelineItem,
  TimelineSeparator as MuiTimelineSeparator,
  TimelineConnector as MuiTimelineConnector,
  TimelineItemProps as MuiTimelineItemProps,
  TimelineSeparatorProps as MuiTimelineSeparatorProps,
  TimelineDot as MuiTimelineDot,
  TimelineDotProps as MuiTimelineDotProps,
  TimelineContent as MuiTimelineContent,
  TimelineContentProps as MuiTimelineContentProps,
} from '@mui/lab'

import { StyleProps, styled } from '@mui/material'
import { Typography } from '@components/typography'

const styles: StyleProps = {
  timeline: {
    px: 0,
  },
  timelineConnector: {
    '&.MuiTimelineConnector-root': {
      width: 4,
      height: 20,
      bgcolor: 'N050',
    },
  },
  timelineDot: {
    '&.MuiTimelineDot-root': {
      margin: 1.5,
      bgcolor: 'N500',
      width: 40,
      height: 40,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      svg: {
        width: 16,
        height: 16,
      },
    },
  },
  timelineContent: {
    '&.MuiTimelineContent-root': {
      p: 0,
      pl: 2,
    },
  },
  timelineItem: {
    '&.MuiTimelineItem-root': {
      alignItems: 'flex-end',
      '&::before': {
        flex: 0,
      },
    },
  },
}

export const TimelineTitle = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  paddingTop: 12,
  whiteSpace: 'nowrap',
  color: theme.palette.N500,
}))

export const Timeline = (props: MuiTimelineProps) => {
  // @ts-ignore
  return <MuiTimeline {...props} sx={styles.timeline} />
}

export const TimelineItem = (props: MuiTimelineItemProps) => (
  <MuiTimelineItem {...props} sx={styles.timelineItem} />
)

export const TimelineContent = (props: MuiTimelineContentProps) => (
  <MuiTimelineContent {...props} sx={styles.timelineContent} />
)

export const TimelineConnector = () => <MuiTimelineConnector sx={styles.timelineConnector} />

export const TimelineDot = (props: MuiTimelineDotProps) => (
  <MuiTimelineDot {...props} sx={styles.timelineDot} />
)

export const TimelineSeparator = (props: MuiTimelineSeparatorProps) => (
  <MuiTimelineSeparator {...props} />
)
