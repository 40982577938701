import { useMutation } from '@tanstack/react-query'
import { useApi } from './api'
import { ApiError, ResetDataSchema } from '../generated'

export const useResetData = () => {
  const api = useApi()

  return useMutation<void, ApiError, ResetDataSchema>({
    mutationFn: (data) => api.resetData({ requestBody: data }),
  })
}
