import { CustomPalette } from '@core/styles/theme'
import { Badge as MuiBadge, BadgeProps as MuiBadgeProps, StyleProps, alpha } from '@mui/material'

const styles = {
  root: {
    '& .MuiBadge-standard ': {
      borderRadius: 2,
      px: '6px',
      minWidth: 20,
      height: 16,
    },
    '&.Badge-dot-pulse': {
      '& .MuiBadge-dot': {
        animation: 'pulsing 2s infinite',
        borderRadius: '50%',
        transition: 'all 0.2s',
        '@keyframes pulsing': {
          from: {
            boxShadow: (theme) => `0 0 0 0 ${theme.palette.B100}`,
          },
          '70%': {
            boxShadow: (theme) => `0 0 0 4px ${alpha(theme.palette.B100, 0)}`,
          },
          to: {
            boxShadow: (theme) => `0 0 0 0 ${alpha(theme.palette.B100, 0)}`,
          },
        },
      },
    },
  },
} satisfies StyleProps

type BadgeProps = Omit<
  MuiBadgeProps,
  | 'anchorOrigin'
  | 'componentsProps'
  | 'components'
  | 'invisible'
  | 'max'
  | 'overlap'
  | 'ref'
  | 'showZero'
  | 'slots'
  | 'slotProps'
  | 'style'
  | 'sx'
> & {
  bgcolor?: keyof CustomPalette | string
  textColor?: keyof CustomPalette | string
  pulse?: boolean
}

export const Badge = (props: BadgeProps) => {
  const { bgcolor, textColor, pulse = false, ...rest } = props

  return (
    <MuiBadge
      className={pulse ? 'Badge-dot-pulse' : ''}
      {...rest}
      sx={{
        '& .MuiBadge-badge': {
          color: textColor,
          bgcolor,
        },
        ...styles.root,
      }}
    />
  )
}
