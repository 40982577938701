import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@mui/material'

export type TooltipProps = Omit<
  MuiTooltipProps,
  | 'sx'
  | 'components'
  | 'componentProps'
  | 'PopperComponent'
  | 'classes'
  | 'PopperProps'
  | 'slotProps'
  | 'disablePortal'
>

const styles = {
  root: {
    bgcolor: 'N800',
    borderRadius: '3px',
    px: 1,
    py: 0.25,
    fontSize: 12,
    lineHeight: 1.33,
    maxWidth: 150,
    '&.MuiTooltip-tooltip': {
      '&.MuiTooltip-tooltipPlacementTop, &.MuiTooltip-tooltipPlacementBottom': {
        mb: 0.5,
        mt: 0.5,
      },
      '&.MuiTooltip-tooltipPlacementLeft': {
        mr: 0.5,
      },
      '&.MuiTooltip-tooltipPlacementRight': {
        ml: 0.5,
      },
    },
  },
}

export const Tooltip = (props: TooltipProps) => {
  return <MuiTooltip {...props} componentsProps={{ tooltip: { sx: styles.root } }} />
}
