import { forwardRef, HTMLAttributes, ReactNode } from 'react'
import { Close } from '@mui/icons-material'
import {
  alpha,
  Box,
  ClickAwayListener,
  Popper as MuiPopper,
  PopperProps as MuiPopperProps,
  StyleProps,
} from '@mui/material'

import { IconButton } from '@components/button'
import { Tooltip } from '@components/tooltip'

export type PopperProps = Pick<
  MuiPopperProps,
  'anchorEl' | 'children' | 'placement' | 'disablePortal'
> & {
  children: ReactNode
  onClose?: () => void
  offset?: number[] | (({}: { placement: MuiPopperProps['placement'] }) => number[])
  fallbackPlacements?: MuiPopperProps['placement'][]
} & HTMLAttributes<HTMLDivElement>

const styles: StyleProps = {
  root: {
    maxWidth: 500,
    bgcolor: 'N000',
    zIndex: 9000,
    position: 'relative',
    borderRadius: '2px',
    px: 3,
    pt: 5,
    pb: 4,
    boxShadow: (theme) =>
      `0 8px 16px -4px ${alpha(theme.palette.N900, 0.25)}, 0 0 1px 0 ${alpha(
        theme.palette.N900,
        0.31
      )}`,
    '&.Popper-noClose': {
      pt: 3,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  close: {
    position: 'absolute',
    top: 8,
    right: 8,
  },
}

export const Popper = forwardRef<HTMLDivElement, PopperProps>(
  (
    { anchorEl, children, onClose, offset = [0, 8], fallbackPlacements, ...rest }: PopperProps,
    ref
  ) => {
    const onClickAway = (event: MouseEvent | TouchEvent) => {
      if (event.target !== anchorEl) {
        onClose?.()
      }
    }

    return (
      <MuiPopper
        {...rest}
        ref={ref}
        sx={styles.root}
        className={!onClose ? `Popper-noClose` : undefined}
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset,
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: fallbackPlacements,
            },
          },
        ]}
      >
        <ClickAwayListener onClickAway={onClickAway}>
          <Box>
            {onClose && (
              <Box sx={styles.header}>
                <Tooltip title="Close" placement="left">
                  <Box sx={styles.close}>
                    <IconButton aria-label="close" onClick={() => onClose()}>
                      <Close fontSize="small" />
                    </IconButton>
                  </Box>
                </Tooltip>
              </Box>
            )}
            {children}
          </Box>
        </ClickAwayListener>
      </MuiPopper>
    )
  }
)
