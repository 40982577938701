import { useMemo } from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ApiError } from '@core/api/generated'
import { toast } from '@components/toaster'
import { isArray } from 'lodash'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const throwOnError = (error: unknown) => {
  if (error instanceof ApiError) {
    switch (error.status) {
      case 422:
        return false
    }
  }

  return true
}

type ClientProviderProps = {
  children: React.ReactNode
}

export const APIProvider = ({ children }: ClientProviderProps) => {
  const queryClient = useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          staleTime: 1000 * 20,
          refetchOnWindowFocus: false,
          refetchOnReconnect: false,
          retry: false,
          throwOnError,
        },
        mutations: {
          throwOnError,
          onError: (error) => {
            if (error instanceof ApiError) {
              if (error.body.detail) {
                toast.error({
                  heading: 'Validation Error',
                  message: isArray(error.body.detail)
                    ? error.body.detail.map((e: { msg: string }) => e.msg).join('\n ')
                    : error.body.detail,
                })
              } else {
                toast.error({ heading: 'Error', message: error.body })
              }
            }
          },
        },
      },
    })
  }, [])

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}
