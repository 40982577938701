import { Checkbox as MuiCheckBox, CheckboxProps as MuiCheckBoxProps } from '@mui/material'
import {
  CheckBoxRounded as CheckBoxIcon,
  IndeterminateCheckBoxRounded as Indeterminate,
} from '@mui/icons-material'

type CheckboxProps = Omit<
  MuiCheckBoxProps,
  | 'action'
  | 'edge'
  | 'focusVisibleClassName'
  | 'LinkComponent'
  | 'indeterminateIcon'
  | 'sx'
  | 'style'
>

export const Checkbox = (props: CheckboxProps) => {
  const { indeterminate, ...rest } = props
  return (
    <MuiCheckBox
      sx={{
        color: 'N040',
      }}
      checkedIcon={
        indeterminate ? (
          <Indeterminate
            data-testid="IndeterminateIcon"
            sx={{
              color: 'B400',
            }}
          />
        ) : (
          <CheckBoxIcon
            data-testid="CheckedIcon"
            sx={{
              color: 'B400',
            }}
          />
        )
      }
      {...rest}
    />
  )
}
