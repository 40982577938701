import {
  FormControl,
  TextField as MuiTextField,
  StandardTextFieldProps,
  StyleProps,
} from '@mui/material'

export const styles: StyleProps = {
  root: {
    m: 0,
    '& fieldset': { border: 'none' },
    '& .MuiInputBase-root': {
      bgcolor: 'background.default',
      border: (theme) => `2px solid ${theme.palette.N040}`,
      py: 1,
      px: 2,
      borderRadius: '3px',
      '&.MuiInputBase-multiline': {
        p: 0,
        '& .MuiInputBase-input': {
          py: 1,
          pl: 2,
        },
      },
      '&.Mui-focused': {
        borderColor: 'focus.main',
      },
      '&.Mui-error': {
        borderColor: 'error.main',
      },
      '&.MuiInputBase-colorSuccess': {
        borderColor: 'success.main',
      },
      '&.MuiInputBase-colorWarning': {
        borderColor: 'warning.main',
      },
      '&.Mui-disabled': {
        borderColor: 'N010',
        backgroundColor: 'N010',
      },
      '&.MuiInputBase-sizeSmall': {
        py: 0.75,
        px: 1,
        fontSize: 14,
      },
    },
    '& .MuiInputBase-input': {
      p: 0,
      color: 'N100',
      height: 'auto',
      '&.Mui-disabled': {
        color: 'N070',
      },
      '&.MuiInputBase-inputSizeSmall': {
        p: 0,
      },
    },
    '& .MuiInputAdornment-root': {
      mr: 0.5,
    },
    '& .MuiInputBase-adornedStart': {
      pl: 1,
      svg: {
        color: 'N500',
      },
      '& .MuiInputAdornment-positionStart': {
        mr: 1,
      },
    },
  },
  label: {
    position: 'relative',
    fontSize: 14,
    fontWeight: 500,
    transform: 'unset',
    mb: 1,
    color: 'text.primary',
    '&.Mui-error, &.Mui-disabled, &.Mui-focused': {
      color: 'text.primary',
    },
  },
  helperText: {
    mt: 0.5,
    ml: 0,
    color: 'text.secondary',
    fontSize: 13,
    fontWeight: 500,
    lineHeight: '16px',
    '&.Mui-disabled': {
      color: 'N200',
    },
    '&[color=success]': {
      color: 'G400',
    },
  },
}

export type TextFieldProps = Omit<
  StandardTextFieldProps,
  'FormHelperTextProps' | 'InputLabelProps' | 'style' | 'SelectProps' | 'sx'
>

export const TextField = ({
  color,
  'aria-label': ariaLabel,
  InputProps,
  ...rest
}: TextFieldProps) => {
  return (
    <FormControl fullWidth>
      <MuiTextField
        {...rest}
        color={color}
        InputProps={{ 'aria-label': ariaLabel, ...InputProps }}
        InputLabelProps={{ shrink: true, sx: styles.label }}
        FormHelperTextProps={{
          sx: styles.helperText,
          color,
        }}
        sx={styles.root}
      />
    </FormControl>
  )
}

TextField.displayName = 'TextField'
