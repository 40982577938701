import { Histogram, Skeleton } from '@components'
import { useFrameworkStats, useProject } from '@core/api/hooks'
import { useParams } from 'react-router-dom'

export const ReviewTagDistribution = () => {
  const { projectId } = useParams() as { projectId: string }
  const { data: project } = useProject(projectId)
  const { data, isLoading } = useFrameworkStats(project?.framework.id)

  if (isLoading || !data) return <Skeleton height={130} variant="rectangular" />

  const stats = data.map((frameworkStats) => {
    return {
      name: frameworkStats.framework.shortName,
      value: frameworkStats.stats.tagged,
    }
  })

  return <Histogram data={stats} />
}
