import { createAsyncThunk } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'
import { AppDispatch, RootState } from './store'
import { useCallback, useState } from 'react'

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
  rejectValue: string
  extra: { history: ReturnType<typeof useNavigate> }
}>()

export function useToggle(defaultValue?: boolean): [boolean, () => void] {
  const [value, setValue] = useState(!!defaultValue)

  const toggle = useCallback(() => setValue((x) => !x), [])

  return [value, toggle]
}
