export const LOGIN_ROUTE = '/'
export const PROJECTS_ROUTE = '/projects'
export const REVIEW_ROUTE = '/review/:projectId'
export const REVIEW_DETAIL_ROUTE = '/review/detail/:unitId'
export const RESULTS_ROUTE = '/results/:projectId'

export const routeMap = {
  LOGIN_ROUTE,
  PROJECTS_ROUTE,
  REVIEW_ROUTE,
  REVIEW_DETAIL_ROUTE,
  RESULTS_ROUTE,
} as const

export type Route = (typeof routeMap)[keyof typeof routeMap]
