import { Box, StyleProps, alpha } from '@mui/material'
import {
  Swiper as SwiperComponent,
  SwiperProps as SwiperComponentProps,
  SwiperSlide as SwiperSlideComponent,
  SwiperSlideProps as SwiperSlideComponentProps,
} from 'swiper/react'
import { Navigation } from 'swiper/modules'
import { IconButton } from '@mui/material'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import 'swiper/css'
import 'swiper/css/navigation'

export type SwiperProps = Pick<
  SwiperComponentProps,
  'children' | 'onSlideChange' | 'simulateTouch' | 'initialSlide'
>
export type SwiperSlideProps = Pick<SwiperSlideComponentProps, 'children'>

export const SwiperSlide = (props: SwiperSlideProps) => {
  return <SwiperSlideComponent {...props} />
}
SwiperSlide.displayName = 'SwiperSlide'

const styles: StyleProps = {
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    px: 3,
    pt: 3,
    pb: 4,
    '& .swiper-wrapper': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .swiper-slide': {
      width: '100% !important',
    },
    '& .swiper-button-disabled': {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    '& .MuiButtonBase-root': {
      width: 32,
      height: 32,
      color: 'N600',
      '&:hover': {
        bgcolor: (theme) => alpha(theme.palette.N200, 0.16),
      },
      '&.Mui-disabled': {
        bgcolor: 'transparent',
        opacity: 0.5,
        color: (theme) => alpha(theme.palette.N600, 0.87),
      },
      '&:focus': {
        bgcolor: 'N040',
      },
      '&[aria-label=previous]': {
        mr: 3,
      },
      '&[aria-label=next]': {
        ml: 3,
      },
    },
  },
}

export const Swiper = (props: SwiperProps) => {
  return (
    <Box sx={styles.root} className="SwiperContainer">
      <IconButton aria-label="previous" className="swiper-button image-swiper-button-prev">
        <ChevronLeft />
      </IconButton>

      <SwiperComponent
        {...props}
        slidesPerView={1}
        navigation={{
          nextEl: '.image-swiper-button-next',
          prevEl: '.image-swiper-button-prev',
          disabledClass: 'swiper-button-disabled',
        }}
        modules={[Navigation]}
      />

      <IconButton aria-label="next" className="swiper-button image-swiper-button-next">
        <ChevronRight />
      </IconButton>
    </Box>
  )
}
