export const searchParamsToObject = (
  searchParams: URLSearchParams
): { [k: string]: string | string[] } => {
  const queryKeys = Array.from(searchParams.keys())

  return queryKeys.reduce(
    (reducer, key) => {
      const value = searchParams.getAll(key).filter((v) => v)

      if (!value.length) return reducer

      return { ...reducer, [key]: value.length > 1 ? value : value[0] }
    },
    {} as { [k: string]: string | string[] }
  )
}
