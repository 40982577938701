export interface Node {
  name?: string
  parent?: Node | null
}

export const composeNodeName = (node: Node): string | null => {
  if (!node.name && !node.parent) return null

  if (node.parent) {
    return `${composeNodeName(node.parent)}: ${node.name}`
  }

  return node.name ?? null
}
