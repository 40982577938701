import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import {
  alpha,
  Box,
  IconButton,
  Drawer as MuiDrawer,
  DrawerProps as MuiDrawerProps,
  StyleProps,
} from '@mui/material'

import { Tooltip } from '@components/tooltip'

export type DrawerProps = Pick<
  MuiDrawerProps,
  'variant' | 'open' | 'children' | 'disablePortal'
> & {
  anchor: 'left' | 'right'
  onClose?: (event: object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick') => void
}
const styles: StyleProps = {
  root: {
    height: '100%',
    '& .MuiDrawer-paper': {
      p: 1.5,
      maxWidth: 500,
      boxShadow: (theme) => `0 12px 24px -6px ${alpha(theme.palette.N900, 0.25)}`,
      '.Drawer-header': {
        display: 'flex',
        alignItems: 'center',
        '& .MuiButtonBase-root[aria-label=close]': {
          bgcolor: 'transparent',
          width: 32,
          height: 32,
          ':hover': {
            bgcolor: 'N040',
            filter: 'brightness(0.9)',
          },
        },
      },
    },
    '& .MuiBackdrop-root': {
      bgcolor: (theme) => alpha(theme.palette.N900, 0.45),
    },
    '&.Drawer-permanent': {
      '& .MuiDrawer-paper': {
        position: 'relative',
        boxShadow: (theme) =>
          `0 1px 1px 0 ${alpha(theme.palette.N900, 0.25)}, 0 0 1px 0 ${alpha(
            theme.palette.N900,
            0.31
          )}`,
        minWidth: 80,
        width: 'max-content',
        p: 0,
      },
      '.MuiDrawer-paperAnchorRight': {
        borderLeft: 'none',
      },
      '.MuiDrawer-paperAnchorLeft': {
        borderRight: 'none',
      },
    },
    '.MuiDrawer-paperAnchorRight': {
      '.Drawer-header': {
        justifyContent: 'flex-start',
      },
    },
    '.MuiDrawer-paperAnchorLeft': {
      '.Drawer-header': {
        justifyContent: 'flex-end',
      },
    },
  },
}

export const Drawer = ({ anchor, children, variant, onClose, ...rest }: DrawerProps) => {
  return (
    <MuiDrawer
      {...rest}
      className={variant && `Drawer-${variant}`}
      sx={styles.root}
      anchor={anchor}
      variant={variant}
      onClose={onClose}
    >
      {variant !== 'permanent' && (
        <Box className="Drawer-header">
          <Tooltip title="Close" placement={anchor}>
            <IconButton aria-label="close" onClick={(e) => onClose && onClose(e, 'closeClick')}>
              {anchor === 'left' ? <ChevronLeft /> : <ChevronRight />}
            </IconButton>
          </Tooltip>
        </Box>
      )}

      {children}
    </MuiDrawer>
  )
}
