import {
  HTMLAttributes,
  MouseEvent,
  useState,
  forwardRef,
  useEffect,
  useRef,
  SyntheticEvent,
} from 'react'
import {
  Autocomplete as MuiAutocomplete,
  AutocompleteProps as MuitAutocompleteProps,
  StyleProps,
  alpha,
  Box,
  AutocompleteCloseReason,
} from '@mui/material'
import InputAdornment from '@mui/material/InputAdornment'
import { Search } from '@mui/icons-material'
import { Popper } from '@components/popper'
import { TextField } from '@components/textfield'
import { Typography } from '@components/typography'
import { useToggle } from '@core/hooks'

export type AutocompleteOption = {
  label: string
  value: string | number | object
  description?: {
    title: string
    content: string
  }
}

export type AutocompleteProps = Pick<
  MuitAutocompleteProps<AutocompleteOption, boolean | undefined, boolean | undefined, false>,
  | 'disabled'
  | 'filterOptions'
  | 'filterSelectedOptions'
  | 'getOptionLabel'
  | 'groupBy'
  | 'loading'
  | 'multiple'
  | 'onChange'
  | 'open'
  | 'options'
  | 'PopperComponent'
  | 'size'
  | 'value'
  | 'disableClearable'
  | 'ListboxComponent'
  | 'renderOption'
> & {
  placeholder: string
}

const styles: StyleProps = {
  autocomplete: {
    '& .MuiInputBase-root': {
      '&.MuiOutlinedInput-root': {
        pr: 0,
      },
      '&.MuiInputBase-sizeSmall': {
        '& .MuiAutocomplete-input': {
          p: 0,
        },
      },
      '& .MuiAutocomplete-input': {
        p: 0,
      },
    },
  },
  popper: {
    '& .MuiPaper-root': {
      borderRadius: '0 0 4px 4px',
      boxShadow: (theme) =>
        `0 8px 16px -4px ${alpha(theme.palette.N900, 0.25)}, 0 0 1px 0 ${alpha(
          theme.palette.N900,
          0.31
        )}`,
    },
    '& .MuiAutocomplete-listbox': {
      py: 1,
      overflow: 'overlay',
      '& .MuiAutocomplete-option': {
        px: 2,
        py: 1,
        lineHeight: 1.5,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
        display: 'block',
        '&[aria-selected="false"].Mui-focused': {
          bgcolor: 'N030',
        },
      },
      '&::-webkit-scrollbar': {
        width: 18,
        bgcolor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        bgcolor: (theme) => alpha(theme.palette.N900, 0.36),
        backgroundClip: 'padding-box',
        border: '6px solid rgba(0, 0, 0, 0)',
        borderRadius: 3,
      },
    },
  },
  searchIcon: {
    color: 'N600',
    '&.MuiSvgIcon-fontSizeSmall': {
      height: 16,
      width: 16,
    },
  },
  description: {
    maxWidth: 300,
    '& .MuiTypography-body2': {
      fontWeight: 'normal',
    },
  },
  descriptionHeader: {
    height: 'auto',
    minHeight: 40,
    px: 2,
    py: 1,
    my: 1,
    bgcolor: 'N030',
  },
  descriptionBody: {
    px: 2,
    pb: 3,
  },
}

export const Autocomplete = forwardRef(
  ({ open = false, placeholder, size, ...props }: AutocompleteProps, ref) => {
    const [isOpen, toggleOpen] = useToggle(open)
    const [currentOption, setCurrentOption] = useState<{
      option: AutocompleteOption
      target: HTMLElement
    } | null>(null)
    const inputRef = useRef<HTMLInputElement>(null)
    const descriptionPopper = useRef<HTMLDivElement>(null)

    useEffect(() => {
      setCurrentOption(null)
    }, [])

    const handleRenderOption = (
      optionProps: HTMLAttributes<HTMLLIElement>,
      option: AutocompleteOption
    ) => {
      const handleMouseDown = (event: MouseEvent<HTMLLIElement>) => {
        if (!option.description) return

        setCurrentOption({ option, target: event.currentTarget as HTMLLIElement })
      }

      const handleMouseLeave = ({ relatedTarget }: MouseEvent<HTMLLIElement>) => {
        if (
          relatedTarget &&
          (relatedTarget as Node).nodeType &&
          descriptionPopper.current?.contains(relatedTarget as Node)
        ) {
          return
        }

        setCurrentOption(null)
      }

      return (
        <li
          onMouseEnter={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseLeave}
          aria-haspopup={Boolean(option.description)}
          {...optionProps}
        >
          {option.label}
        </li>
      )
    }

    const handleClose = (_: SyntheticEvent, reason: AutocompleteCloseReason) => {
      if (open || (currentOption && reason === 'blur')) return

      toggleOpen()
    }

    return (
      <>
        <MuiAutocomplete
          ref={ref}
          renderInput={({ InputProps, ...rest }) => (
            <TextField
              {...rest}
              size={size}
              placeholder={placeholder}
              InputProps={{
                ...InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize={size} sx={styles.searchIcon} />
                  </InputAdornment>
                ),
              }}
              inputRef={inputRef}
            />
          )}
          renderOption={handleRenderOption}
          slotProps={{ popper: { sx: styles.popper } }}
          sx={styles.autocomplete}
          forcePopupIcon={false}
          renderTags={() => null}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          open={isOpen}
          onOpen={toggleOpen}
          onClose={handleClose}
          disablePortal
          {...props}
        />

        {currentOption && (
          <Popper
            anchorEl={currentOption.target}
            placement="left-start"
            fallbackPlacements={['right-start']}
            offset={({ placement }) => (placement?.startsWith('right') ? [-8, -18] : [-8, -12])}
            ref={descriptionPopper}
            style={{ padding: 0 }}
            onMouseLeave={() => {
              setCurrentOption(null)
              inputRef.current?.focus()
            }}
          >
            <Box sx={styles.description}>
              <Box sx={styles.descriptionHeader}>
                <Typography variant="h5">{currentOption.option.description?.title}</Typography>
              </Box>

              <Box sx={styles.descriptionBody}>
                <Typography variant="body2">{currentOption.option.description?.content}</Typography>
              </Box>
            </Box>
          </Popper>
        )}
      </>
    )
  }
)
