export interface Colors {
  blue: {
    B025: '#edf2fa'
    B050: '#deebff'
    B075: '#b3d4ff'
    B100: '#4c9aff'
    B200: '#2684ff'
    B300: '#0065ff'
    B400: '#0052cc'
    B500: '#0747a6'
  }
  green: {
    G050: '#e3fcef'
    G075: '#abf5d1'
    G100: '#79f2c0'
    G200: '#57d9a3'
    G300: '#36b37e'
    G400: '#00875a'
    G500: '#006644'
  }
  neutral: {
    N000: '#ffffff'
    N010: '#f4f5f7'
    N020: '#fafbfc'
    N030: '#ebecf0'
    N040: '#dfe1e5'
    N050: '#c1c7d0'
    N060: '#b3bac5'
    N070: '#a5adba'
    N080: '#97a0af'
    N090: '#8993a4'
    N100: '#7a869a'
    N200: '#6b778c'
    N300: '#5e6c84'
    N400: '#505f79'
    N500: '#42526e'
    N600: '#344563'
    N700: '#253858'
    N800: '#172b4d'
    N900: '#091e42'
    N1000: '#000000'
  }
  purple: {
    P050: '#eae6ff'
    P075: '#c0b6f2'
    P100: '#998dd9'
    P200: '#8777d9'
    P300: '#6554c0'
    P400: '#5243aa'
    P500: '#403294'
  }
  red: {
    R050: '#ffebe5'
    R075: '#ffbdad'
    R100: '#ff8f73'
    R200: '#ff7452'
    R300: '#ff5630'
    R400: '#de350b'
    R500: '#bf2600'
  }
  teal: {
    T050: '#e6fcff'
    T075: '#b3f5ff'
    T100: '#79e2f2'
    T200: '#00c7e6'
    T300: '#00b8d9'
    T400: '#00a3bf'
    T500: '#008da6'
  }
  yellow: {
    Y050: '#fffae6'
    Y075: '#fff0b3'
    Y100: '#ffe380'
    Y200: '#ffc400'
    Y300: '#ffab00'
    Y400: '#ff991f'
    Y500: '#ff8b00'
  }
  aubergine: {
    A050: '#faf7fa'
    A100: '#F3EDF3'
    A200: '#E9DEE9'
    A300: '#DBC8DA'
    A400: '#C4A5C3'
    A500: '#AE84AD'
    A600: '#986497'
    A700: '#795079'
    A800: '#614060'
    A900: '#4F344F'
    A950: '#2C1D2C'
  }
  orange: {
    O050: '#FEF7F5'
    O100: '#FCEDE8'
    O200: '#F9DBD1'
    O300: '#F4C2B1'
    O400: '#ED9A7E'
    O500: '#E56E46'
    O600: '#CE491D'
    O700: '#A63B17'
    O800: '#842F12'
    O900: '#6D270F'
    O950: '#3E1609'
  }
}

export const colors: Colors = {
  blue: {
    B025: '#edf2fa',
    B050: '#deebff',
    B075: '#b3d4ff',
    B100: '#4c9aff',
    B200: '#2684ff',
    B300: '#0065ff',
    B400: '#0052cc',
    B500: '#0747a6',
  },
  green: {
    G050: '#e3fcef',
    G075: '#abf5d1',
    G100: '#79f2c0',
    G200: '#57d9a3',
    G300: '#36b37e',
    G400: '#00875a',
    G500: '#006644',
  },
  neutral: {
    N000: '#ffffff',
    N010: '#f4f5f7',
    N020: '#fafbfc',
    N030: '#ebecf0',
    N040: '#dfe1e5',
    N050: '#c1c7d0',
    N060: '#b3bac5',
    N070: '#a5adba',
    N080: '#97a0af',
    N090: '#8993a4',
    N100: '#7a869a',
    N200: '#6b778c',
    N300: '#5e6c84',
    N400: '#505f79',
    N500: '#42526e',
    N600: '#344563',
    N700: '#253858',
    N800: '#172b4d',
    N900: '#091e42',
    N1000: '#000000',
  },
  purple: {
    P050: '#eae6ff',
    P075: '#c0b6f2',
    P100: '#998dd9',
    P200: '#8777d9',
    P300: '#6554c0',
    P400: '#5243aa',
    P500: '#403294',
  },
  red: {
    R050: '#ffebe5',
    R075: '#ffbdad',
    R100: '#ff8f73',
    R200: '#ff7452',
    R300: '#ff5630',
    R400: '#de350b',
    R500: '#bf2600',
  },
  teal: {
    T050: '#e6fcff',
    T075: '#b3f5ff',
    T100: '#79e2f2',
    T200: '#00c7e6',
    T300: '#00b8d9',
    T400: '#00a3bf',
    T500: '#008da6',
  },
  yellow: {
    Y050: '#fffae6',
    Y075: '#fff0b3',
    Y100: '#ffe380',
    Y200: '#ffc400',
    Y300: '#ffab00',
    Y400: '#ff991f',
    Y500: '#ff8b00',
  },
  aubergine: {
    A050: '#faf7fa',
    A100: '#F3EDF3',
    A200: '#E9DEE9',
    A300: '#DBC8DA',
    A400: '#C4A5C3',
    A500: '#AE84AD',
    A600: '#986497',
    A700: '#795079',
    A800: '#614060',
    A900: '#4F344F',
    A950: '#2C1D2C',
  },
  orange: {
    O050: '#FEF7F5',
    O100: '#FCEDE8',
    O200: '#F9DBD1',
    O300: '#F4C2B1',
    O400: '#ED9A7E',
    O500: '#E56E46',
    O600: '#CE491D',
    O700: '#A63B17',
    O800: '#842F12',
    O900: '#6D270F',
    O950: '#3E1609',
  },
}

export const allColors = {
  ...colors.blue,
  ...colors.green,
  ...colors.neutral,
  ...colors.purple,
  ...colors.red,
  ...colors.teal,
  ...colors.yellow,
  ...colors.aubergine,
  ...colors.orange,
}

export const colorScale = (colorNames: (keyof Colors)[]) => {
  return colorNames.map((colorName) => Object.keys(colors[colorName]).reverse()).flat()
}
