/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Actions {
  UNIT_REOPENED = 'unit_reopened',
  UNIT_FINALIZED = 'unit_finalized',
  UNIT_FLAGGED = 'unit_flagged',
  UNIT_UNFLAGGED = 'unit_unflagged',
  TAG_ADDED = 'tag_added',
  TAG_REORDERED = 'tag_reordered',
  TAG_DELETED = 'tag_deleted',
  EVIDENCE_ADDED = 'evidence_added',
  COMMENT_ADDED = 'comment_added',
}
