import { LockClosedIcon as HeroLockClosedIcon } from '@heroicons/react/24/solid'
import { PencilIcon as HeroPencilIcon } from '@heroicons/react/24/solid'
import { TagIcon as HeroTagIcon } from '@heroicons/react/24/solid'
import { styled } from '@mui/material'
import { ChatBubbleOvalLeftEllipsisIcon } from '@heroicons/react/24/solid'

type ActivityTimelineIconProps = {
  type: string
  status: string
}

const size = {
  height: 16,
  width: 16,
}

const LockIcon = styled(HeroLockClosedIcon)(size)
const PencilIcon = styled(HeroPencilIcon)(size)
const TagIcon = styled(HeroTagIcon)(size)
const ChatIcon = styled(ChatBubbleOvalLeftEllipsisIcon)(size)

export default function ActivityTimelineIcon({ type, status }: ActivityTimelineIconProps) {
  let Icon = null

  if (type.startsWith('tag')) {
    Icon = <TagIcon />
  } else if (type.startsWith('unit') && status === 'DONE') {
    Icon = <LockIcon />
  } else if (type.startsWith('unit')) {
    Icon = <PencilIcon />
  } else if (type.startsWith('comment') || type.startsWith('evidence')) {
    Icon = <ChatIcon />
  }

  return Icon
}
