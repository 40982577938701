import React from 'react'
import {
  TableBody,
  TableCell,
  TableContainer as MuiTableContainer,
  TableHead,
  tableHeadClasses,
  TableRow,
  tableRowClasses,
  TableSortLabel,
  alpha,
  Box,
  Table as MuiTable,
  TableProps as MuiTableProps,
  TableContainerProps as MuiTableContainerProps,
  TableCellProps as MuiTableCellProps,
  TableBodyProps as MuiTableBodyProps,
  TableHeadProps as MuiTableHeadProps,
  TableRowProps as MuiTableRowProps,
  StyleProps,
} from '@mui/material'
import {
  KeyboardArrowDownRounded,
  KeyboardArrowUpRounded,
  UnfoldMoreRounded,
} from '@mui/icons-material'

const styles: StyleProps = {
  container: {
    bgcolor: 'N000',
    boxShadow: (theme) =>
      `0 1px 1px 0 ${alpha(theme.palette.N900, 0.25)}, 0 0 1px 0 ${alpha(
        theme.palette.N900,
        0.31
      )}`,
  },
  head: {
    [`&.${tableHeadClasses.root}`]: {
      th: {
        bgcolor: 'N000',
        fontWeight: 500,
      },
    },
  },
  row: {
    [`&.${tableRowClasses.root}`]: {
      [`&.${tableRowClasses.selected}`]: {
        bgcolor: 'N010',
      },
      td: {
        px: 3,
      },
      'td, th': {
        border: 'none',
      },
    },
  },
  body: {
    margin: 2,
    borderRadius: 0.75,
    tr: {
      '&:hover': {
        bgcolor: 'N010',
        '&.emptyRow': {
          bgcolor: 'transparent',
        },
      },
    },
  },
  cell: {
    p: 0,
    px: 3,
    height: 56,
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
  },
  stickyCell: {
    position: 'sticky',
    left: 0,
    bgcolor: 'N000',
    '&.MuiTableCell-head': {
      zIndex: (theme) => theme.zIndex.appBar - 1,
    },
    '&.Hovered-cell': {
      bgcolor: 'N010',
    },
  },
  emptyRowContent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 56,
  },
  sortLabel: {
    '& .MuiSvgIcon-root': {
      opacity: 0.5,
      color: 'N800',
    },
    '&.Mui-active': {
      '& .MuiSvgIcon-root': {
        opacity: 1,
        color: 'N800',
      },
    },
  },
}

type TableContainerProps = Omit<MuiTableContainerProps, 'sx' | 'style'>
type TableProps = Omit<MuiTableProps, 'sx' | 'style'> & {
  empty?: boolean
  fixed?: boolean
}
type TableBodyProps = Omit<
  MuiTableBodyProps,
  'padding' | 'size' | 'stickyHeader' | 'sx' | 'style'
> & {
  hasData?: boolean
}
type TableHeadProps = Omit<MuiTableHeadProps, 'sx' | 'style'>
type TableRowProps = Omit<MuiTableRowProps, 'hover' | 'scope' | 'size' | 'sx' | 'style'>
type TableCellProps = Omit<
  MuiTableCellProps,
  'component' | 'padding' | 'scope' | 'size' | 'sx' | 'style'
>

export enum TableOrder {
  asc = 'asc',
  desc = 'desc',
}

type SortProps = {
  name: string
  column: string
  children: React.ReactNode
  order: TableOrder
  onClick: (name: string, order: TableOrder) => void
}

type EmptyCellProps = {
  numberOfColumns: number
  children: React.ReactNode
}

export const Table = ({ empty = false, fixed = false, ...rest }: TableProps) => {
  return (
    <MuiTable
      sx={{
        tableLayout: fixed ? 'fixed' : 'auto',
        borderCollapse: 'separate',
        borderSpacing: '0px 2px',
        ...(empty && {
          opacity: 0.5,
        }),
      }}
      {...rest}
    />
  )
}

export const Cell = (props: TableCellProps) => {
  return <TableCell sx={styles.cell} {...props} />
}

export const StickyCell = (props: TableCellProps) => {
  return <TableCell sx={styles.stickyCell} {...props} className="hovered" />
}

export const Body = (props: TableBodyProps) => {
  const { hasData, ...rest } = props
  return (
    <TableBody
      sx={{
        ...(!hasData && {
          '&:before, &:after': {
            content: '""',
            display: 'block',
            height: 16,
          },
          ...styles.body,
        }),
      }}
      {...rest}
    />
  )
}
export const Head = (props: TableHeadProps) => <TableHead sx={styles.head} {...props} />
export const Row = (props: TableRowProps) => (
  <TableRow
    sx={styles.row}
    {...props}
    onMouseOver={(e) => {
      e.currentTarget.firstElementChild?.classList.add('Hovered-cell')
    }}
    onMouseLeave={(e) => {
      props.onMouseLeave?.(e)
      e.currentTarget.firstElementChild?.classList.remove('Hovered-cell')
    }}
  />
)
export const TableContainer = (props: TableContainerProps) => (
  <MuiTableContainer sx={styles.container} {...props} />
)

export const TableSort = ({ name, column, order, onClick, children }: SortProps) => {
  const handleClick = () => {
    onClick(name, order === TableOrder.asc ? TableOrder.desc : TableOrder.asc)
  }

  const IconComponent = () => {
    const icons = {
      [TableOrder.asc]: KeyboardArrowUpRounded,
      [TableOrder.desc]: KeyboardArrowDownRounded,
    }
    const Icon = icons[order] || UnfoldMoreRounded

    return (
      <Box pt={1} pl={1}>
        {column === name && <Icon />}
      </Box>
    )
  }

  return (
    <TableSortLabel
      active={!!order}
      IconComponent={IconComponent}
      aria-sort={column !== name ? 'none' : order === TableOrder.desc ? 'descending' : 'ascending'}
      direction={order}
      hideSortIcon={false}
      onClick={handleClick}
      data-testid={`table-sort-${name}`}
      sx={styles.sortLabel}
    >
      {children}
    </TableSortLabel>
  )
}

export const EmptyRow = (props: EmptyCellProps) => {
  const { numberOfColumns, children } = props
  return (
    <Row className="emptyRow">
      <Cell colSpan={numberOfColumns}>
        <Box sx={styles.emptyRowContent}>{children}</Box>
      </Cell>
    </Row>
  )
}
