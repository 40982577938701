import React from 'react'
import ReactDOM from 'react-dom'
import { Outlet, RouterProvider, ScrollRestoration } from 'react-router-dom'

import axe from '@axe-core/react'
import { routeMap } from '@core/constants/routes'
import { APIProvider, AuthProvider, ReduxProvider, ThemeProvider } from '@core/providers'
import Layout from '@pages/layout'
import { ErrorBoundary } from '@core/error-boundary'
import { Toaster } from '@components/toaster'

import Review from '@pages/review'
import Results from '@pages/results'

import '@bootstrap/dayjs'
import { sentryCreateBrowserRouter } from '@core/api/sentry'
import '@bootstrap/fonts'
import { AuthenticationGuard } from '@core/auth/guard'

const axeConf = {
  rules: [{ id: 'tags', tags: ['wcag2a', 'wcag2aa'] }],
}

if (import.meta.env.DEV) {
  axe(React, ReactDOM, 1000, axeConf)
}

const router = sentryCreateBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <APIProvider>
          <ReduxProvider>
            <ThemeProvider>
              <Outlet />
              <Toaster />
              <ScrollRestoration getKey={(location) => location.pathname} />
            </ThemeProvider>
          </ReduxProvider>
        </APIProvider>
      </AuthProvider>
    ),
    errorElement: (
      <ThemeProvider>
        <ErrorBoundary />
      </ThemeProvider>
    ),
    children: [
      {
        path: routeMap.LOGIN_ROUTE,
        async lazy() {
          const Page = await import('@pages/login')

          return {
            Component: Page.default,
          }
        },
      },
      {
        element: <AuthenticationGuard component={Layout} />,
        children: [
          {
            path: routeMap.PROJECTS_ROUTE,
            async lazy() {
              const Page = await import('@pages/projects')

              return {
                Component: Page.default,
              }
            },
          },
          {
            path: routeMap.REVIEW_ROUTE,
            Component: Review,
          },
          {
            path: routeMap.RESULTS_ROUTE,
            Component: Results,
          },
          {
            path: routeMap.REVIEW_DETAIL_ROUTE,
            async lazy() {
              const Page = await import('@pages/review-detail')

              return {
                Component: () => <AuthenticationGuard component={Page.default} />,
              }
            },
          },
        ],
      },
      {
        path: '/403',
        async lazy() {
          const { ForbiddenErrorPage } = await import('@pages/error')

          return {
            Component: ForbiddenErrorPage,
          }
        },
      },
      {
        path: '/404',
        async lazy() {
          const { NotFoundErrorPage } = await import('@pages/error')

          return {
            Component: NotFoundErrorPage,
          }
        },
      },
      {
        path: '/500',
        async lazy() {
          const { ServerErrorPage } = await import('@pages/error')

          return {
            Component: ServerErrorPage,
          }
        },
      },
    ],
  },
])

const App = () => {
  return <RouterProvider router={router} />
}

export default App
