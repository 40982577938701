import {
  IconButton as MuiIconButton,
  IconButtonProps as MuiIconButtonProps,
  StyleProps,
  alpha,
} from '@mui/material'
import { forwardRef } from 'react'

export type IconButtonProps = Omit<
  MuiIconButtonProps,
  | 'disableElevation'
  | 'disableFocusRipple'
  | 'edge'
  | 'focusVisibleClassName'
  | 'touchRippleRef'
  | 'TouchRippleProps'
  | 'sx'
  | 'style'
  | 'size'
>

const styles: StyleProps = {
  root: {
    height: 32,
    width: 32,
    p: 1,
    color: (theme) => `${alpha(theme.palette.N600, 0.87)}`,
    '&:hover': {
      background: (theme) => `${alpha(theme.palette.N200, 0.16)}`,
    },
    '&:disabled': {
      background: 'transparent',
      color: (theme) => `${alpha(theme.palette.N600, 0.87)}`,
      opacity: 0.5,
    },
  },
}

export const IconButton: React.FC<IconButtonProps> = forwardRef((props: IconButtonProps, ref) => {
  return <MuiIconButton {...props} sx={styles.root} ref={ref} />
})
