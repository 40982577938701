import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  StyleProps,
  alpha,
} from '@mui/material'

type AppBarProps = Omit<
  MuiAppBarProps,
  'color' | 'enableColorOnDark' | 'position' | 'elevation' | 'sx' | 'square' | 'style' | 'variant'
>

const styles: StyleProps = {
  appBar: {
    bgcolor: 'N000',
    boxShadow: (theme) =>
      `0 12px 24px -6px ${alpha(theme.palette.N900, 0.16)}, 0 0 1px 0 ${alpha(
        theme.palette.N900,
        0.24
      )}`,
    height: 48,
  },
}
export const AppBar = (props: AppBarProps) => {
  return <MuiAppBar sx={styles.appBar} {...props} />
}
