import { useRouteError } from 'react-router-dom'
import { ForbiddenErrorPage, NotFoundErrorPage, ServerErrorPage } from '@pages/error'
import { isAxiosError, AxiosError } from 'axios'
import { ApiError } from './api/generated'

export const ErrorBoundary = () => {
  const error = useRouteError() as (Error & { status: number }) | AxiosError | ApiError

  if (isAxiosError(error)) {
    switch (error.code) {
      case 'ERR_NETWORK':
        return <ServerErrorPage />
    }
  }

  if (error) {
    switch (error.status) {
      case 404:
        return <NotFoundErrorPage />
      case 403:
        return <ForbiddenErrorPage />
      default:
        return <ServerErrorPage />
    }
  }

  throw error
}
