import { Box, Grid, StyleProps, useTheme } from '@mui/material'
import { Typography } from '@components'
const styles: StyleProps = {
  title: {
    pb: 2,
  },
  colorBox: {
    width: 16,
    height: 16,
    borderRadius: '3px',
    pl: 2,
    mr: 1,
  },
  container: {
    flexDirection: 'column',
    justifyContent: 'center',
    bgcolor: 'N010',
    p: 2,
    my: 3,
    height: 64,
    alignItems: 'center',
  },
  key: {
    pr: 2,
  },
}

const keys: { color: 'O600' | 'O500' | 'A600' | 'A500' | 'O300'; label: string }[] = [
  { color: 'O600', label: 'Tag is not appropriate' },
  { color: 'O500', label: 'Tag is acceptable but there is a better one' },
  { color: 'A600', label: 'Rationales sentence structure is undesired' },
  { color: 'A500', label: 'Rationale contains inaccurate information' },
  { color: 'O300', label: 'Other' },
]

export const ReportLegend = () => {
  const theme = useTheme()
  return (
    <Grid container sx={styles.container}>
      <Box sx={styles.key}>
        <Typography variant="body1" fontWeight={600}>
          Key:
        </Typography>
      </Box>
      <Grid item display="flex" alignItems="center" container spacing={0}>
        {keys.map((key, index) => (
          <Grid item display="flex" alignItems="center" key={index} pr={3}>
            <Box sx={{ ...styles.colorBox, bgcolor: theme.palette[key.color] }} />
            <Typography variant="body2" fontWeight={500}>
              {key.label}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
