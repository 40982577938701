import { Checkbox, Typography } from '@components'
import { Modal } from '@components/modal'
import { Grid, Box, FormControlLabel, FormControl, FormLabel, StyleProps } from '@mui/material'
import { useMemo, useState } from 'react'
import { Frameworks } from '@core/api/generated'
import { useResetData } from '@core/api/hooks'
import { Loader } from '@components/loader'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    px: 2,
  },
  selectAll: {
    mt: 3,
    mb: 2,
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 2,
    pt: 2,
    pb: 4,
  },
} satisfies StyleProps

export type ResetModalProps = {
  open: boolean
  onCancel: () => void
  onClose: () => void
}

export const ResetModal = ({ open, onCancel, onClose }: ResetModalProps) => {
  const resetDataMutation = useResetData()
  const isMutating = resetDataMutation.isPending || resetDataMutation.isSuccess

  const getFrameworksInitialState = useMemo(() => {
    return Object.values(Frameworks).map((framework) => ({ name: framework, checked: false }))
  }, [])
  const [frameworks, setFrameworks] = useState(getFrameworksInitialState)
  const allSelected = frameworks.every((framework) => framework.checked)
  const someSelected = frameworks.some((framework) => framework.checked)

  const handleSelectAll = () => {
    const selectedFrameworks = frameworks.map((framework) => ({
      ...framework,
      checked: !allSelected,
    }))
    setFrameworks(selectedFrameworks)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFrameworks = [...frameworks]
    const framework = newFrameworks.find((dataset) => dataset.name === event.target.name)

    if (!framework) return

    framework.checked = event.target.checked
    setFrameworks([...newFrameworks])
  }

  const handleConfirm = () => {
    const selectedFrameworks = frameworks
      .filter((dataset) => dataset.checked)
      .map((dataset) => dataset.name)

    resetDataMutation.mutateAsync({ frameworks: selectedFrameworks }).then(() => {
      window.location.href = '/projects'
    })
  }
  const beforeClose = (callback: () => void) => {
    setFrameworks(getFrameworksInitialState)
    callback()
  }

  return (
    <Modal
      open={open}
      onConfirm={isMutating ? undefined : handleConfirm}
      onCancel={isMutating ? undefined : () => beforeClose(onCancel)}
      onClose={isMutating ? undefined : () => beforeClose(onClose)}
    >
      <Box sx={styles.root}>
        <Typography variant="h4">Reset data</Typography>

        {isMutating ? (
          <Box sx={styles.loading}>
            <Loader />
            <Typography variant="body2">
              We are currently resetting the data. Please wait to be redirected.
            </Typography>
          </Box>
        ) : (
          <FormControl component="fieldset" variant="standard">
            <FormLabel component="legend">
              Please choose the frameworks to reset the system's data to its initial state.
            </FormLabel>

            <Box sx={styles.selectAll}>
              <FormControlLabel
                label="Select all"
                control={
                  <Checkbox
                    checked={someSelected}
                    indeterminate={!allSelected}
                    onChange={handleSelectAll}
                  />
                }
              />
            </Box>

            <Grid container spacing={1}>
              {frameworks.map((dataset) => (
                <Grid key={dataset.name} item xs={6}>
                  <FormControlLabel
                    key={dataset.name}
                    label={dataset.name}
                    control={
                      <Checkbox
                        name={dataset.name}
                        checked={dataset.checked}
                        onChange={handleChange}
                      />
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </FormControl>
        )}
      </Box>
    </Modal>
  )
}
