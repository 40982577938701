import { Box, StyleProps } from '@mui/material'

import { Typography } from '@components/typography'
import { memo } from 'react'
import { Skeleton } from '@components/skeleton'
import { EvidenceHighlightText } from '@containers/evidences/evidence-highlight-text'
import { UseEvidenceProps } from '@containers/evidences/use-evidence'
import { PlainTextSchema } from '@core/api/generated'

const styles: StyleProps = {
  root: {
    width: '100%',
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
}

export type PlainTextProps = {
  content: PlainTextSchema | undefined
  tagShortName?: string
  evidence?: UseEvidenceProps
  labels?: {
    text?: string
  }
}

export const PlainText = memo(({ content, tagShortName, evidence, labels }: PlainTextProps) => {
  if (!content)
    return (
      <>
        <Skeleton variant="rounded" height={26} />
        <Skeleton variant="rounded" height={130} />
      </>
    )

  return (
    <Box sx={styles.root}>
      <Box sx={styles.text}>
        <Typography variant="h4">{labels?.text || 'Plain Text'}</Typography>
        <EvidenceHighlightText
          origin={`${'text.'}${tagShortName}`}
          text={content.text}
          fontWeight="normal"
          {...evidence}
        />
      </Box>
    </Box>
  )
})
