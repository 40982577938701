import { Chip, Popper, Swiper, SwiperSlide, Typography } from '@components'
import { TagSchema } from '@core/api/generated'
import { Node, composeNodeName } from '@core/formatters/node'
import { colorScale } from '@core/styles/colors'
import { Box, StyleProps } from '@mui/material'

const CHIP_COLORS = colorScale(['purple', 'teal'])

const styles: StyleProps = {
  swiper: {
    '& .SwiperContainer': { p: 0 },
  },
}

export type TagSwiperProps = {
  tags: TagSchema[]
  anchorEl: HTMLElement | null
  initialIndex: number
  onClose: () => void
  onSelectTag: (index: number) => void
}

export const TagSwiper = ({
  tags,
  anchorEl,
  initialIndex,
  onClose,
  onSelectTag,
}: TagSwiperProps) => {
  return (
    <Popper placement="bottom-start" anchorEl={anchorEl} onClose={onClose}>
      <Box sx={styles.swiper}>
        <Swiper initialSlide={initialIndex} onSlideChange={(e) => onSelectTag(e.activeIndex)}>
          <>
            {tags.map((tag, index) => {
              const { framework } = tag
              const { shortName, description } = framework

              return (
                <SwiperSlide key={index}>
                  <Chip
                    size="small"
                    key={index}
                    label={shortName}
                    bgcolor={CHIP_COLORS[index] as string}
                    textColor="N000"
                  />

                  <Box mt={2}>
                    <Typography>{description || composeNodeName(framework as Node)}</Typography>
                  </Box>
                </SwiperSlide>
              )
            })}
          </>
        </Swiper>
      </Box>
    </Popper>
  )
}
