import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit'

// @TODO: define permissions types and defaults values
interface Permissions {
  canResetCustomerData: boolean
  canDeleteProjects: boolean
}

export interface RouteMatch {
  path: string
  url: string
  params: Record<string, string>
  isExact: boolean
}

interface State {
  customer: {
    id: string
    name: string
    hasOptionRationale: boolean
  } | null
  user: {
    id: string
    email: string
    name: string
    role: string
    canResetCustomerData: boolean
    canDeleteProjects: boolean
    picture: string
  } | null
  permissions: Permissions | null
  routeMatch: RouteMatch | null
}

export function getInitialState(): State {
  return {
    customer: null,
    user: null,
    permissions: null,
    routeMatch: null,
  }
}

export const routeEnter = createAction<RouteMatch>('main/routeEnter')
export const routeLeave = createAction<RouteMatch>('main/routeLeave')

export const slice = createSlice({
  name: 'main',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
    // @todo: construct permissions from user and customer
  },
  extraReducers: (builder) => {
    builder.addCase(routeEnter, (state, action) => {
      state.routeMatch = action.payload
    })
  },
})

export default slice.reducer

export const actions = {
  ...slice.actions,
  routeEnter,
  routeLeave,
}
