import { memo } from 'react'
import { UseEvidenceProps } from '@containers/evidences/use-evidence'
import { ItemTypeSchema, PlainTextSchema } from '@core/api/generated'
import { PlainText } from './plain-text'
import { Item, Loading } from './item'

type UnitProps = {
  content: ItemTypeSchema | PlainTextSchema | undefined
  material: string | undefined
  tagShortName?: string
  evidence?: UseEvidenceProps
  labels?: {
    stem?: string
    stimulus?: string
    text?: string
  }
}

export const Unit = memo(({ material, content, ...props }: UnitProps) => {
  if (!material) return <Loading />
  if (material === 'PLAIN_TEXT') {
    return <PlainText content={content as PlainTextSchema} {...props} />
  }
  return <Item content={content as ItemTypeSchema} {...props} />
})
