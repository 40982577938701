import {
  Modal as MuiModal,
  ModalProps as MuiModalProps,
  StyleProps,
  Box,
  alpha,
} from '@mui/material'
import { Tooltip } from '@components/tooltip'
import { Button, IconButton } from '@components/button'
import { Clear } from '@mui/icons-material'

export type ModalProps = Omit<MuiModalProps, 'sx' | 'classes'> & {
  onClose?: (event: object, reason: 'backdropClick' | 'escapeKeyDown' | 'closeClick') => void
  onCancel?: () => void
  onConfirm?: () => void
}

const styles: StyleProps = {
  root: {
    '& .MuiBackdrop-root': {
      bgcolor: (theme) => alpha(theme.palette.N900, 0.42),
    },
  },
  modal: {
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
    width: 600,
    minHeight: 200,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    px: 3,
    pt: 5,
    pb: 4,
    bgcolor: 'N000',
    outline: 'none',
    boxShadow: (theme) =>
      `0 0 1px 0 ${alpha(theme.palette.N900, 0.31)}, 0 20px 32px -8px ${alpha(
        theme.palette.N900,
        0.25
      )})}`,

    '& .MuiIconButton-root.Modal-closeButton': {
      position: 'absolute',
      top: 12,
      right: 12,
    },
  },
  content: {
    flex: 1,
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 2,
  },
}

export const Modal = ({ children, onClose, onCancel, onConfirm, ...props }: ModalProps) => {
  return (
    <MuiModal sx={styles.root} {...props}>
      <Box sx={styles.modal}>
        {onClose && (
          <Tooltip title="Close" placement="left">
            <IconButton
              className="Modal-closeButton"
              aria-label="close"
              onClick={(e) => onClose?.(e, 'closeClick')}
            >
              <Clear />
            </IconButton>
          </Tooltip>
        )}

        <Box sx={styles.content}>{children}</Box>

        {(onCancel || onConfirm) && (
          <Box sx={styles.footer}>
            {onCancel && (
              <Button
                aria-label="cancel"
                variant="contained"
                color="secondary"
                onClick={() => onCancel?.()}
              >
                Cancel
              </Button>
            )}
            {onConfirm && (
              <Button
                aria-label="confirm"
                variant="contained"
                color="primary"
                onClick={() => onConfirm?.()}
              >
                Confirm
              </Button>
            )}
          </Box>
        )}
      </Box>
    </MuiModal>
  )
}
