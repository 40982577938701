import { SvgIcon } from '@mui/material'
import Icon from './icon.svg?react'

const styles = {
  root: {
    animation: 'spin 2s linear infinite',
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(0deg)',
      },
      '100%': {
        transform: 'rotate(360deg)',
      },
    },
  },
}

export type LoaderProps = {
  width?: number
  height?: number
}

export const Loader = ({ width = 48, height = 48 }: LoaderProps) => {
  return (
    <SvgIcon sx={{ ...styles.root, width, height }}>
      <Icon />
    </SvgIcon>
  )
}
