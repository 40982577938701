/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CommentCreateSchema } from '../models/CommentCreateSchema';
import type { CommentSchema } from '../models/CommentSchema';
import type { CommentUpdateSchema } from '../models/CommentUpdateSchema';
import type { FrameworkStatsSchema } from '../models/FrameworkStatsSchema';
import type { PagedActivitySchema } from '../models/PagedActivitySchema';
import type { PagedFrameworkSchema } from '../models/PagedFrameworkSchema';
import type { PagedProjectSchema } from '../models/PagedProjectSchema';
import type { PagedRootCommentSchema } from '../models/PagedRootCommentSchema';
import type { PagedUnitSchema } from '../models/PagedUnitSchema';
import type { ProjectBulkDeleteSchema } from '../models/ProjectBulkDeleteSchema';
import type { ProjectSchema } from '../models/ProjectSchema';
import type { ProjectUpdateSchema } from '../models/ProjectUpdateSchema';
import type { RationaleCreateSchema } from '../models/RationaleCreateSchema';
import type { RationaleFeedbackSchema } from '../models/RationaleFeedbackSchema';
import type { RationaleSchema } from '../models/RationaleSchema';
import type { RationaleVoteCreateSchema } from '../models/RationaleVoteCreateSchema';
import type { ReadNotificationSchema } from '../models/ReadNotificationSchema';
import type { ResetDataSchema } from '../models/ResetDataSchema';
import type { Response } from '../models/Response';
import type { TagDeleteSchema } from '../models/TagDeleteSchema';
import type { TagFeedbackSchema } from '../models/TagFeedbackSchema';
import type { UnitDetailSchema } from '../models/UnitDetailSchema';
import type { UnitSchema } from '../models/UnitSchema';
import type { UnitTagCreateSchema } from '../models/UnitTagCreateSchema';
import type { UnitTagUpdateSchema } from '../models/UnitTagUpdateSchema';
import type { UnitUpdateSchema } from '../models/UnitUpdateSchema';
import type { UploadSchema } from '../models/UploadSchema';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DefaultService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * List Activities
   * @returns PagedActivitySchema OK
   * @throws ApiError
   */
  public listActivities({
    projectId,
    action,
    createdAtStart,
    createdAtEnd,
    page = 1,
    pageSize = 10,
  }: {
    projectId?: string,
    action?: Array<string>,
    createdAtStart?: string,
    createdAtEnd?: string,
    page?: number,
    pageSize?: number,
  }): CancelablePromise<PagedActivitySchema> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/activity',
      query: {
        'projectId': projectId,
        'action': action,
        'createdAtStart': createdAtStart,
        'createdAtEnd': createdAtEnd,
        'page': page,
        'pageSize': pageSize,
      },
    });
  }
  /**
   * Create Comment
   * @returns CommentSchema OK
   * @throws ApiError
   */
  public createComment({
    requestBody,
  }: {
    requestBody: CommentCreateSchema,
  }): CancelablePromise<CommentSchema> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/comments',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List Comments
   * @returns PagedRootCommentSchema OK
   * @throws ApiError
   */
  public listComments({
    objectId,
    origins,
    page = 1,
    pageSize = 10,
  }: {
    objectId?: string,
    origins?: (Array<string> | null),
    page?: number,
    pageSize?: number,
  }): CancelablePromise<PagedRootCommentSchema> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/comments',
      query: {
        'objectId': objectId,
        'origins': origins,
        'page': page,
        'pageSize': pageSize,
      },
    });
  }
  /**
   * Update Comment
   * @returns CommentSchema OK
   * @throws ApiError
   */
  public updateComment({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: CommentUpdateSchema,
  }): CancelablePromise<CommentSchema> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/comments/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Delete Comment
   * @returns void
   * @throws ApiError
   */
  public deleteComment({
    id,
  }: {
    id: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/comments/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * List Frameworks
   * @returns PagedFrameworkSchema OK
   * @throws ApiError
   */
  public listFrameworks({
    name,
    shortName,
    isRoot,
    orderBy,
    page = 1,
    pageSize = 10,
  }: {
    name?: string,
    shortName?: string,
    isRoot?: boolean,
    orderBy?: string,
    page?: number,
    pageSize?: number,
  }): CancelablePromise<PagedFrameworkSchema> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/frameworks/',
      query: {
        'name': name,
        'shortName': shortName,
        'isRoot': isRoot,
        'orderBy': orderBy,
        'page': page,
        'pageSize': pageSize,
      },
    });
  }
  /**
   * Get Framework Leafs
   * @returns PagedFrameworkSchema OK
   * @throws ApiError
   */
  public getFrameworkLeafs({
    id,
    page = 1,
    pageSize = 10,
  }: {
    id: string,
    page?: number,
    pageSize?: number,
  }): CancelablePromise<PagedFrameworkSchema> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/frameworks/{id}/leafs',
      path: {
        'id': id,
      },
      query: {
        'page': page,
        'pageSize': pageSize,
      },
    });
  }
  /**
   * Get Framework Stats
   * @returns FrameworkStatsSchema OK
   * @throws ApiError
   */
  public getFrameworkStats({
    id,
    projectId,
  }: {
    id: string,
    projectId?: string,
  }): CancelablePromise<Array<FrameworkStatsSchema>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/frameworks/{id}/stats',
      path: {
        'id': id,
      },
      query: {
        'projectId': projectId,
      },
    });
  }
  /**
   * Reset Data
   * @returns void
   * @throws ApiError
   */
  public resetData({
    requestBody,
  }: {
    requestBody: ResetDataSchema,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/management/reset-data',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Read Notifications
   * @returns void
   * @throws ApiError
   */
  public readNotifications({
    requestBody,
  }: {
    requestBody: ReadNotificationSchema,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/notifications/read',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List Projects
   * @returns PagedProjectSchema OK
   * @throws ApiError
   */
  public listProjects({
    material,
    frameworkName,
    favorite,
    orderBy,
    page = 1,
    pageSize = 10,
  }: {
    material?: Array<string>,
    frameworkName?: Array<string>,
    favorite?: boolean,
    orderBy?: string,
    page?: number,
    pageSize?: number,
  }): CancelablePromise<PagedProjectSchema> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/',
      query: {
        'material': material,
        'frameworkName': frameworkName,
        'favorite': favorite,
        'orderBy': orderBy,
        'page': page,
        'pageSize': pageSize,
      },
    });
  }
  /**
   * Bulk Delete Projects
   * @returns void
   * @throws ApiError
   */
  public bulkDeleteProjects({
    requestBody,
  }: {
    requestBody: ProjectBulkDeleteSchema,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/projects/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Get Project
   * @returns ProjectSchema OK
   * @throws ApiError
   */
  public getProject({
    id,
  }: {
    id: string,
  }): CancelablePromise<ProjectSchema> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Update Project
   * @returns ProjectSchema OK
   * @throws ApiError
   */
  public updateProject({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: ProjectUpdateSchema,
  }): CancelablePromise<ProjectSchema> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/projects/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Delete Project
   * @returns void
   * @throws ApiError
   */
  public deleteProject({
    id,
  }: {
    id: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/projects/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Export Project
   * @returns any OK
   * @throws ApiError
   */
  public exportProject({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/projects/{id}/export',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Get Rationale
   * @returns RationaleSchema OK
   * @throws ApiError
   */
  public getRationale({
    id,
  }: {
    id: string,
  }): CancelablePromise<RationaleSchema> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/rationales/{id}',
      path: {
        'id': id,
      },
    });
  }
  /**
   * Rationale Vote
   * @returns RationaleSchema OK
   * @throws ApiError
   */
  public rationaleVote({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: RationaleVoteCreateSchema,
  }): CancelablePromise<RationaleSchema> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/rationales/{id}/vote',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Upsert Rationale
   * @returns RationaleSchema OK
   * @throws ApiError
   */
  public upsertRationale({
    requestBody,
  }: {
    requestBody: RationaleCreateSchema,
  }): CancelablePromise<RationaleSchema> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/rationales/',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List Rationale Feedbacks
   * @returns RationaleFeedbackSchema OK
   * @throws ApiError
   */
  public listRationaleFeedbacks({
    id,
    limit = 5,
  }: {
    id: string,
    limit?: number,
  }): CancelablePromise<Array<RationaleFeedbackSchema>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/rationales/{id}/feedbacks',
      path: {
        'id': id,
      },
      query: {
        'limit': limit,
      },
    });
  }
  /**
   * List Feedback
   * @returns TagFeedbackSchema OK
   * @throws ApiError
   */
  public listFeedback({
    id,
    limit = 5,
  }: {
    id: string,
    limit?: number,
  }): CancelablePromise<Array<TagFeedbackSchema>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/tags/{id}/feedbacks',
      path: {
        'id': id,
      },
      query: {
        'limit': limit,
      },
    });
  }
  /**
   * Delete Tag
   * @returns void
   * @throws ApiError
   */
  public deleteTag({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: TagDeleteSchema,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/tags/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * List Units
   * @returns PagedUnitSchema OK
   * @throws ApiError
   */
  public listUnits({
    projectId,
    isFlagged,
    hasTags,
    hasComments,
    tagName,
    page = 1,
    pageSize = 10,
  }: {
    projectId?: string,
    isFlagged?: boolean,
    hasTags?: boolean,
    hasComments?: boolean,
    tagName?: Array<string>,
    page?: number,
    pageSize?: number,
  }): CancelablePromise<PagedUnitSchema> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/units/',
      query: {
        'projectId': projectId,
        'isFlagged': isFlagged,
        'hasTags': hasTags,
        'hasComments': hasComments,
        'tagName': tagName,
        'page': page,
        'pageSize': pageSize,
      },
    });
  }
  /**
   * Get Unit
   * @returns UnitDetailSchema OK
   * @throws ApiError
   */
  public getUnit({
    id,
    projectId,
    isFlagged,
    hasTags,
    hasComments,
    tagName,
  }: {
    id: string,
    projectId?: string,
    isFlagged?: boolean,
    hasTags?: boolean,
    hasComments?: boolean,
    tagName?: Array<string>,
  }): CancelablePromise<UnitDetailSchema> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/units/{id}',
      path: {
        'id': id,
      },
      query: {
        'projectId': projectId,
        'isFlagged': isFlagged,
        'hasTags': hasTags,
        'hasComments': hasComments,
        'tagName': tagName,
      },
    });
  }
  /**
   * Update Unit
   * @returns UnitSchema OK
   * @throws ApiError
   */
  public updateUnit({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: UnitUpdateSchema,
  }): CancelablePromise<UnitSchema> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/units/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Create Tag
   * @returns UnitSchema OK
   * @throws ApiError
   */
  public createTag({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: UnitTagCreateSchema,
  }): CancelablePromise<UnitSchema> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/units/{id}/tags',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Bulk Update Tags
   * @returns UnitSchema OK
   * @throws ApiError
   */
  public bulkUpdateTags({
    id,
    requestBody,
  }: {
    id: string,
    requestBody: Array<UnitTagUpdateSchema>,
  }): CancelablePromise<UnitSchema> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/units/{id}/tags',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Upload File
   * @returns Response Created
   * @throws ApiError
   */
  public uploadFile({
    requestBody,
  }: {
    requestBody: UploadSchema,
  }): CancelablePromise<Response> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/uploads',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
