/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UnitUpdateSchema = {
  isFlagged?: boolean;
  status?: UnitUpdateSchema.status;
};
export namespace UnitUpdateSchema {
  export enum status {
    REVIEW = 'REVIEW',
    DONE = 'DONE',
  }
}
