import { useQuery } from '@tanstack/react-query'
import { ApiError, PagedActivitySchema } from '../generated'
import { useApi } from './api'

export const useListActivities = (query: {
  projectId?: string
  action?: string[]
  createdAtStart?: string
  createdAtEnd?: string
  page?: number
  pageSize?: number
}) => {
  const api = useApi()

  return useQuery<PagedActivitySchema, ApiError>({
    queryKey: ['listActivities', query],
    queryFn: () => api.listActivities(query),
    staleTime: 0,
  })
}
