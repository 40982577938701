import { useState } from 'react'
import dayjs from 'dayjs'
import { DayPicker, DateFormatter, DateRange, SelectRangeEventHandler } from 'react-day-picker'
import 'react-day-picker/dist/style.css'
import { Box, ClickAwayListener, Popper, useTheme } from '@mui/material'
import { allColors } from '@core/styles/colors'
export type DatePickerProps = {
  open: boolean
  anchorEl: null | HTMLElement
  onClickOutside: () => void
  onSelect: (fromValue?: Date, toValue?: Date) => void
}

const css = `
.rdp {
  --rdp-accent-color: ${allColors.P050};
  --rdp-background-color: ${allColors.P050};
  --rdp-selected-color: ${allColors.N800}
}
`

export const DatePicker = ({ open, anchorEl, onClickOutside, onSelect }: DatePickerProps) => {
  const [selectedRange, setSelectedRange] = useState<DateRange | undefined>()
  const theme = useTheme()

  const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | undefined) => {
    setSelectedRange(range)
    onSelect(range?.from, range?.to)
  }

  const formatWeekdayName: DateFormatter = (day) => {
    return dayjs(day).format('dd')[0]
  }

  return (
    <Popper open={open} anchorEl={anchorEl} placement="bottom">
      <ClickAwayListener onClickAway={onClickOutside}>
        <Box>
          <style>{css}</style>
          <DayPicker
            toDate={new Date()}
            mode="range"
            selected={selectedRange}
            numberOfMonths={2}
            formatters={{
              formatWeekdayName,
            }}
            styles={{
              root: {
                background: theme.palette.N000,
                padding: 8,
                borderRadius: 5,
              },
              caption_label: {
                fontWeight: 600,
              },
              head_cell: {
                fontWeight: 500,
              },
            }}
            onSelect={handleRangeSelect}
          />
        </Box>
      </ClickAwayListener>
    </Popper>
  )
}
