import { ActivityLayout } from '@components/activity/activity-layout'

import { Snapshot, UnitSnapshot } from '@components/activity/types'
import { Box, StyleProps, Typography } from '@mui/material'
import { ClearRounded } from '@mui/icons-material'
import { differenceBy } from 'lodash'
import { TagDisplay, WrapLink } from './shared-components'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    maxWidth: '100%',
    gap: 1.5,
  },
  description: {
    display: 'flex',
    alignItems: 'center',
    gap: 3,
  },
  tags: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
  },
} satisfies StyleProps

type ActivityTagDeletedProps = {
  snapshot: Snapshot
}

export const ActivityTagDeleted = ({ snapshot }: ActivityTagDeletedProps) => {
  const { from, to } = snapshot as UnitSnapshot
  const removedTags = differenceBy(from.tags, to.tags, 'id')

  return (
    <ActivityLayout.Root>
      <ActivityLayout.User by={snapshot.by} />

      <ActivityLayout.Content>
        <Box sx={styles.root}>
          <Typography
            variant="body1"
            noWrap
            minWidth={400}
            sx={{
              maxWidth: {
                md: 520,
                lg: 660,
              },
            }}
          >
            Tag removed: <WrapLink to={`/review/detail/${to.id}`}>{to.content.stem}</WrapLink>
          </Typography>

          <Box sx={styles.description}>
            <Typography variant="subtitle2">Needs review</Typography>

            <Box sx={styles.tags}>
              {removedTags.map((tag, index) => {
                return (
                  <Box sx={styles.tag} key={index}>
                    <TagDisplay index={index} tag={tag} />
                    <ClearRounded fontSize="small" />
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Box>
      </ActivityLayout.Content>
    </ActivityLayout.Root>
  )
}
