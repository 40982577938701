import {
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'
import React from 'react'
import * as Sentry from '@sentry/react'
import { ENVIRONMENT, SENTRY_DSN, SENTRY_RELEASE, SENTRY_TRACES_SAMPLE_RATE } from '@core/config'

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
      Sentry.replayIntegration(),
    ],
    tracePropagationTargets: [/.*catalog.finetunelearning.com\/.*/],
    release: `ftc-ui@${SENTRY_RELEASE}`,
    tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE,
    environment: ENVIRONMENT,
  })
}
export const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter)
