import { Skeleton, Typography } from '@components'
import { Activity } from '@components/activity'
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineTitle,
  TimelineDot,
  TimelineContent,
} from '@components/timeline'
import { useListActivities } from '@core/api/hooks/activities'
import { searchParamsToObject } from '@core/converters/search-params'
import { Box, Grid, styled } from '@mui/material'
import dayjs from 'dayjs'
import { useParams, useSearchParams } from 'react-router-dom'
import { ActivitySchema } from '@core/api/generated'
import { Snapshot } from '@components/activity/types'

import ActivityTimelineIcon from './activity-timeline-icon'
import { ActivityLayout } from '@components/activity/activity-layout'
import { useMemo } from 'react'

const TitleDate = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: 16,
  color: theme.palette.N800,
  paddingBottom: 8,
}))

const styles = {
  skeletonTitle: {
    mb: 1,
  },
  emptyBox: {
    opacity: 0.5,
  },
}

const SkeletonTimeline = () => {
  return [...Array(4)].map((key, index) => (
    <TimelineItem key={index}>
      <TimelineSeparator>
        <TimelineConnector />

        <TimelineTitle aria-label="timeline title" sx={styles.skeletonTitle}>
          <Skeleton variant="rounded" width={35} height={14} />
        </TimelineTitle>

        <Box mb={2}>
          <Skeleton variant="circular" width={40} height={40} />
        </Box>

        <TimelineConnector />
      </TimelineSeparator>

      <TimelineContent aria-label="timeline content">
        <ActivityLayout.Root>
          <Grid container alignItems="center">
            <Grid item md={1}>
              <Skeleton variant="circular" width={40} height={40} />
            </Grid>

            <Grid item md={2}>
              <Skeleton width="70%" variant="rounded" height={40} />
            </Grid>

            <Grid item md={9} display="flex" flexDirection="column" gap={2}>
              <Skeleton variant="rounded" width="100%" height={20} />
              <Skeleton variant="rounded" width="100%" height={20} />
            </Grid>
          </Grid>
        </ActivityLayout.Root>
      </TimelineContent>
    </TimelineItem>
  ))
}

export default function ActivityTimeline() {
  const { projectId } = useParams()

  const today = dayjs()
  const [searchParams] = useSearchParams({
    createdAtStart: today.subtract(5, 'day').startOf("day").format(),
    createdAtEnd: today.endOf('day').endOf("day").format(),
  })
  const query = searchParamsToObject(searchParams)

  const { data, isLoading } = useListActivities({ projectId, ...query })

  const dateRangeLabel = useMemo(() => {
    const startDate = dayjs(query.createdAtStart as string)
    const endDate = dayjs(query.createdAtEnd as string)

    if (endDate.isSame(startDate, 'day')) {
      return endDate.isSame(today, 'day') ? 'Today' : endDate.format('MMM D, YYYY')
    }

    const isSameYear = startDate.isSame(endDate, 'year')
    const dateFormat = isSameYear ? 'MMM D' : 'MMM D, YYYY'

    const start = startDate.format(dateFormat)
    const end = endDate.format(dateFormat)

    if (endDate.isSame(today, 'day')) {
      return `${start} - Today`
    }

    return isSameYear ? `${start} - ${end}, ${endDate.year()}` : `${start} - ${end}`
  }, [query.createdAtStart, query.createdAtEnd])

  const formatTimelineDate = (timelineDate: string) => {
    const date = dayjs(timelineDate)

    return date.isSame(today, 'day') ? date.format('hh:mm A') : date.fromNow(true)
  }

  if (isLoading) {
    return (
      <Timeline position="right">
        <SkeletonTimeline />
      </Timeline>
    )
  }

  return (
    <Box>
      <TitleDate aria-label="timeline date">{dateRangeLabel}</TitleDate>

      <Timeline position="right">
        {data?.items.length ? (
          data.items.map((activity: ActivitySchema) => {
            return (
              <Box key={activity.id}>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector />

                    <TimelineTitle aria-label="timeline title">
                      {formatTimelineDate(activity?.createdAt)}
                    </TimelineTitle>

                    <TimelineDot>
                      <ActivityTimelineIcon
                        type={activity.action}
                        status={activity.snapshot.to?.status}
                      />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>

                  <TimelineContent aria-label="timeline content">
                    <Activity snapshot={activity?.snapshot as Snapshot} />
                  </TimelineContent>
                </TimelineItem>
              </Box>
            )
          })
        ) : (
          <Box sx={styles.emptyBox}>
            <Typography>There are no results available within the date range.</Typography>
          </Box>
        )}
      </Timeline>

      <TitleDate aria-label="timeline date">{dateRangeLabel}</TitleDate>
    </Box>
  )
}
