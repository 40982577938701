import { Box, Grid, StyleProps } from '@mui/material'
import { Chip, Menu, MenuButton, MenuItem } from '@components'
import ActivityTimeline from './activity-timeline'
import { Pagination } from '@components/pagination'
import { DatePicker } from '@components/date-picker'
import { useState } from 'react'
import { Button } from '@components/button'
import { useListActivities } from '@core/api/hooks/activities'
import { useParams, useSearchParams } from 'react-router-dom'
import { searchParamsToObject } from '@core/converters/search-params'
import dayjs from 'dayjs'
import { Typography } from '@components'
import { Actions } from '@core/api/generated'
import { ReportLegend } from './report-legend'
import { ReportChart } from './report-charts'

const styles: StyleProps = {
  title: {
    pb: 2,
  },
  paginationFilter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    bgcolor: 'N010',
    p: 2,
    my: 3,
  },
  controlLabel: {
    p: 1,
  },
  menuItem: {
    p: 0,
  },
  resultsTypeFilter: {
    pl: 1,
  },
}

const names = [
  { key: Actions.TAG_REORDERED, value: 'Tags changed' },
  { key: Actions.UNIT_FINALIZED, value: 'Finalized' },
  {
    key: [
      Actions.UNIT_REOPENED,
      Actions.UNIT_FLAGGED,
      Actions.TAG_REORDERED,
      Actions.TAG_DELETED,
      Actions.TAG_ADDED,
      Actions.UNIT_UNFLAGGED,
    ],
    value: 'Tags pending review',
  },
  { key: [Actions.COMMENT_ADDED, Actions.EVIDENCE_ADDED], value: 'Comments' },
]

const FilterTags = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tags = searchParams.getAll('action')
  const query = searchParamsToObject(searchParams)

  if (query?.length || !tags) return null

  return (
    <>
      {tags.map((tag, index) => {
        const selectedName = names.find((name) => name.key.toString().includes(tag))
        return (
          <Box ml={1} key={index}>
            <Chip
              onDelete={() => {
                setSearchParams({ ...query, action: tags.filter((x) => x !== tag) })
              }}
              size="small"
              key={index}
              label={selectedName?.value}
            />
          </Box>
        )
      })}
    </>
  )
}

const ResultsTypeFilter = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const [searchParams, setSearchParams] = useSearchParams()

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const handleSelectChange = (event: React.MouseEvent<HTMLElement>) => {
    const selectedValue = String(event.currentTarget.dataset.value)
    searchParams.set('action', selectedValue)
    searchParams.set('page', '1')
    setSearchParams(searchParams)
    handleClose()
  }

  return (
    <Box sx={styles.resultsTypeFilter}>
      <MenuButton
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        color="secondary"
        onClick={handleClick}
      >
        Results Type
      </MenuButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} disablePortal>
        {names?.map((name) => (
          <MenuItem
            onClick={handleSelectChange}
            disableRipple
            data-value={name.key}
            key={name.key as string}
          >
            {name.value}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  )
}
export const PaginationActivity = () => {
  const { projectId } = useParams()

  const today = dayjs()
  const [searchParams, setSearchParams] = useSearchParams({
    createdAtStart: today.subtract(5, 'day').startOf('day').format(),
    createdAtEnd: today.endOf('day').format(),
  })
  const query = searchParamsToObject(searchParams)

  const { data } = useListActivities({ projectId, ...query })
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const recordsPerPage = 10
  const numberOfPages = Math.ceil((data?.count ?? 0) / recordsPerPage)

  const handleDatePicker = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(!open)
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handlePage = (page: number) => {
    setSearchParams({ ...query, page: page.toString() })
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const handleSelectDate = (fromValue: Date | undefined, toValue: Date | undefined) => {
    const queryString = {
      ...Object.fromEntries(searchParams),
    }

    if (fromValue && !toValue) {
      queryString.createdAtStart = dayjs(fromValue).startOf('day').toISOString()
      queryString.createdAtEnd = dayjs(fromValue).endOf('day').toISOString()
    } else if (fromValue && toValue) {
      queryString.createdAtStart = dayjs(fromValue).startOf('day').toISOString()
      queryString.createdAtEnd = dayjs(toValue).endOf('day').toISOString()
    } else if (!fromValue && !toValue) {
      delete queryString.createdAtStart
      delete queryString.createdAtEnd
    }

    setSearchParams(queryString)
  }

  return (
    <Grid container sx={styles.paginationFilter}>
      <Grid item md={1}>
        <Typography variant="body1" fontWeight={600}>
          Filter By:
        </Typography>
      </Grid>

      <Grid item md={2} display="flex" alignItems="center">
        <Button variant="contained" color="secondary" onClick={handleDatePicker}>
          Date Range
        </Button>

        <DatePicker
          open={open}
          anchorEl={anchorEl}
          onClickOutside={handleClose}
          onSelect={(fromValue, toValue) => handleSelectDate(fromValue, toValue)}
        />

        <ResultsTypeFilter />
      </Grid>

      <Grid item md={6} display="flex" justifyContent="flex-start">
        <FilterTags />
      </Grid>

      <Grid item md={3}>
        {numberOfPages > 1 && (
          <Pagination
            count={numberOfPages}
            page={parseInt(searchParams.get('page') as string) || 1}
            color="primary"
            onChange={(_, e) => handlePage(e)}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default function ResultsActivity() {
  return (
    <>
      <Box sx={styles.title}>
        <Typography variant="h4">AI Report</Typography>
      </Box>
      <ReportLegend />
      <ReportChart />
      <Box sx={styles.title}>
        <Typography variant="h4">User Activity</Typography>
      </Box>

      <PaginationActivity />
      <ActivityTimeline />
      <PaginationActivity />
    </>
  )
}
