import {
  MenuProps as MuiMenuProps,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  MenuItemProps as MuiMenuItemProps,
  IconButtonProps as MuiIconButtonProps,
  IconButton as MuiIconButton,
  StyleProps,
} from '@mui/material'
import { alpha } from '@mui/material/styles'
import { colors } from '@core/styles/colors'
import { Button, ButtonProps } from '@components/button'

const styles: StyleProps = {
  container: {
    '& .MuiPaper-root': {
      borderRadius: 0.25,
      py: 1,
      mt: 1,
      minWidth: 180,
      color: 'N800',
      boxShadow: `0 12px 24px -6px ${alpha(colors.neutral.N900, 0.25)}, 0 0 1px 0 ${alpha(
        colors.neutral.N900,
        0.31
      )}`,
      '& .MuiMenu-list': {
        p: 0,
      },
      '& .MuiMenuItem-root': {
        py: 1,
        '&:hover': {
          bgcolor: 'N030',
        },
        '& .MuiSvgIcon-root': {
          fontSize: 16,
          mr: 1.5,
        },
      },
    },
  },
}

type MenuProps = Pick<
  MuiMenuProps,
  | 'anchorEl'
  | 'anchorOrigin'
  | 'open'
  | 'onClose'
  | 'children'
  | 'disablePortal'
  | 'transformOrigin'
  | 'disableScrollLock'
>

export const Menu = (props: MenuProps) => {
  return (
    <MuiMenu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
      sx={styles.container}
    />
  )
}

type MenuItemProps = Omit<MuiMenuItemProps, 'classes' | 'dense' | 'sx'>

export const MenuItem = (props: MenuItemProps) => {
  return (
    <MuiMenuItem
      {...props}
      sx={{
        height: 40,
        '&:hover': {
          bgcolor: (theme) => `${alpha(theme.palette.N200, 0.16)} !important`,
        },
      }}
    />
  )
}

export const MenuButton = (props: ButtonProps) => {
  return <Button {...props} aria-haspopup />
}

type IconButtonProps = Omit<MuiIconButtonProps, 'classes' | 'color' | 'edge' | 'sx'>

export const IconMenuButton = (props: IconButtonProps) => {
  return <MuiIconButton {...props} aria-haspopup />
}
