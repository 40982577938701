import { IconButton, IconButtonProps } from '@components/button'
import { StarRounded as FavoriteRoundedIcon } from '@mui/icons-material'
import { styled } from '@mui/material'

type FavoriteProps = Omit<
  IconButtonProps,
  'action' | 'color' | 'disabled' | 'focusVisibleClassName' | 'edge' | 'LinkComponent'
> & {
  checked?: boolean
  size?: number
}

type FavoriteType = {
  checked: boolean
}

const FavoriteButton = styled(IconButton)((props: FavoriteType) => ({ theme }) => ({
  '&.MuiIconButton-root': {
    color: props.checked ? theme.palette.Y400 : theme.palette.N000,
    ...(!props.checked && {
      stroke: theme.palette.N040,
    }),
    strokeWidth: 1.6,
    '&:hover': {
      svg: {
        strokeWidth: 0.5,
        stroke: theme.palette.Y400,
        color: theme.palette.Y400,
      },
    },
  },
}))

export const Favorite = (props: FavoriteProps) => {
  const { checked, size = 20, ...rest } = props

  return (
    <FavoriteButton {...rest} checked={Boolean(checked)}>
      <FavoriteRoundedIcon
        sx={{
          fontSize: size,
        }}
        aria-label={checked ? 'favorite' : 'no favorite'}
      />
    </FavoriteButton>
  )
}
