import { CustomPalette } from '@core/styles/theme'
import { Avatar as MuiAvatar, AvatarProps as MuiAvatarProps } from '@mui/material'
type AvatarProps = Omit<
  MuiAvatarProps,
  | 'children'
  | 'className'
  | 'classes'
  | 'imgProps'
  | 'sizes'
  | 'srcSet'
  | 'sx'
  | 'style'
  | 'ref'
  | 'variant'
> & {
  bgcolor?: keyof CustomPalette | string
  label?: string
  size?: number
}

export const Avatar = (props: AvatarProps) => {
  const { bgcolor, label, size, ...rest } = props
  return (
    <MuiAvatar
      {...rest}
      sx={{
        fontSize: 16,
        bgcolor,
        width: size,
        height: size,
      }}
    >
      {label?.substring(0, 1)}
    </MuiAvatar>
  )
}
