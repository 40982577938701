import {
  Skeleton as MuiSkeleton,
  SkeletonProps as MuiSkeletonProps,
  StyleProps,
} from '@mui/material';
type SkeletonProps = Omit<MuiSkeletonProps, 'sx' | 'style'>;

const styles: StyleProps = {
  skeleton: {
    bgcolor: 'N030',
  },
};

export const Skeleton: React.FC<SkeletonProps> = (props: SkeletonProps) => {
  return <MuiSkeleton {...props} sx={styles.skeleton} />;
};
