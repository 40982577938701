import { SxProps } from '@mui/material'
import { alpha, createTheme } from '@mui/material/styles'

import { colors, Colors } from './colors'

export type CustomPalette = Colors['blue'] &
  Colors['green'] &
  Colors['neutral'] &
  Colors['purple'] &
  Colors['red'] &
  Colors['teal'] &
  Colors['yellow'] &
  Colors['aubergine'] &
  Colors['orange']

declare module '@mui/material/styles' {
  interface PaletteOptions {
    focus: {
      main: string
    }
  }
  interface Palette extends CustomPalette {
    focus: {
      main: string
    }
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsColorOverrides extends Record<keyof CustomPalette, true> {}
}

export const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 0,
      md: 1024,
      lg: 1200,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: colors.neutral.N500,
      light: colors.neutral.N080,
      contrastText: colors.neutral.N000,
    },
    secondary: {
      main: alpha(colors.neutral.N900, 0.04),
      dark: colors.blue.B200,
      light: colors.blue.B100,
      contrastText: colors.neutral.N500,
    },
    success: {
      main: colors.green.G300,
    },
    error: {
      main: colors.red.R400,
    },
    warning: {
      main: colors.yellow.Y300,
    },
    info: {
      main: colors.blue.B200,
    },
    focus: {
      main: colors.blue.B100,
    },
    background: {
      default: colors.neutral.N020,
    },
    text: {
      primary: colors.neutral.N800,
      secondary: colors.neutral.N200,
    },
    ...colors.blue,
    ...colors.green,
    ...colors.neutral,
    ...colors.purple,
    ...colors.red,
    ...colors.teal,
    ...colors.yellow,
    ...colors.aubergine,
    ...colors.orange,
  },

  typography: {
    fontFamily: 'Inter, Helvetica, Arial, sans-serif',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    allVariants: {
      fontWeight: 500,
      color: colors.neutral.N800,
    },
    h1: {
      fontSize: 35,
      lineHeight: 1.31,
      letterSpacing: '-0.77px',
    },
    h2: {
      fontSize: 29,
      lineHeight: 1.31,
      letterSpacing: '-0.61px',
    },
    h3: {
      fontSize: 24,
      lineHeight: 1.33,
      letterSpacing: '-0.47px',
    },
    h4: {
      fontSize: 20,
      lineHeight: 1.3,
      letterSpacing: '-0.33px',
    },
    h5: {
      fontSize: 14,
      lineHeight: 1.36,
      letterSpacing: '-0.09px',
    },
    body1: {
      fontSize: 16,
      lineHeight: 1.36,
      letterSpacing: '-0.18px',
    },
    body2: {
      fontSize: 14,
      lineHeight: 1.36,
      letterSpacing: '-0.09px',
    },
    subtitle1: {
      fontSize: 14,
      lineHeight: 1.36,
      letterSpacing: '-0.09px',
      color: colors.neutral.N500,
    },
    subtitle2: {
      fontSize: 12,
      lineHeight: 1.33,
      letterSpacing: '0.01px',
      color: colors.neutral.N500,
    },
    button: {
      fontSize: 14,
      lineHeight: 1.14,
      letterSpacing: '-0.09px',
      textTransform: 'none',
      color: colors.neutral.N500,
    },
    caption: {
      fontSize: 11,
      lineHeight: 1.36,
      letterSpacing: '0.05px',
      color: colors.neutral.N500,
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          minWidth: '1024px',
          minHeight: '100vh',
        },
        '@font-face': {
          fontSize: '1rem',
          fontFamily: 'Inter, Helvetica, Arial, sans-serif',
          fontWeight: 500,
        },
      },
    },
  },
})
declare module '@mui/material' {
  type StyleProps = Record<string, SxProps<typeof theme>>
}

export default theme
