import { forwardRef } from 'react'
import {
  Box,
  ClickAwayListener,
  Popper as MuiPopper,
  PopperProps,
  StyleProps,
  styled,
} from '@mui/material'

import { Popper } from '@components/popper'

import { Autocomplete, AutocompleteProps } from './autocomplete'

const PopperComponent = ({ style, disablePortal, ...others }: PopperProps) => {
  return (
    <MuiPopper
      {...others}
      disablePortal
      sx={{
        width: '100%',
        transform: 'none !important',
        position: 'relative !important',
        '& .MuiPaper-root': {
          boxShadow: 'none !important',
          borderTop: (theme) => `1px solid ${theme.palette.N040}`,
        },
      }}
    />
  )
}

export type PopperAutocompleteProps = AutocompleteProps & {
  anchorEl: HTMLElement | null
  onClose?: () => void
}

const styles: StyleProps = {
  popper: {
    '& .Popper-noClose': {
      p: 0,
      width: 230,
    },
  },
}

const StyledAutocomplete = styled(Autocomplete)({
  padding: '12px 16px',
})

export const PopperAutocomplete = forwardRef(
  ({ anchorEl, onClose, ...rest }: PopperAutocompleteProps, ref) => {
    const handleClickAway = ({ target }: MouseEvent | TouchEvent) => {
      if (anchorEl && anchorEl.contains(target as Node)) return

      onClose?.()
    }

    return (
      <Box sx={styles.popper}>
        <Popper anchorEl={anchorEl} placement="bottom" disablePortal>
          <ClickAwayListener onClickAway={handleClickAway}>
            <StyledAutocomplete
              {...rest}
              ref={ref}
              PopperComponent={PopperComponent}
              filterSelectedOptions
              open
            />
          </ClickAwayListener>
        </Popper>
      </Box>
    )
  }
)
