import { UseQueryOptions, useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  ApiError,
  PagedUnitSchema,
  TagDeleteSchema,
  UnitSchema,
  UnitTagCreateSchema,
  UnitTagUpdateSchema,
  UnitUpdateSchema,
  UnitDetailSchema,
} from '../generated'
import { useApi } from './api'
import { useSearchParams } from 'react-router-dom'
import { searchParamsToObject } from '@core/converters/search-params'

export const useUnit = (id: string, options?: UseQueryOptions<UnitDetailSchema, ApiError>) => {
  const api = useApi()
  const [searchParams] = useSearchParams()
  const params = { id, ...searchParamsToObject(searchParams) }

  return useQuery<UnitDetailSchema, ApiError>({
    queryKey: ['getUnit', params],
    queryFn: () => api.getUnit(params),
    enabled: !!id,
    ...options,
  })
}

export const useListUnits = ({
  projectId,
  searchParams,
}: {
  projectId: string
  searchParams: URLSearchParams
}) => {
  const api = useApi()
  const params = { projectId, ...Object.fromEntries(searchParams) }

  return useQuery<PagedUnitSchema, ApiError>({
    queryKey: ['listUnits', params],
    queryFn: () => api.listUnits(params),
    enabled: !!projectId,
  })
}

export const useLazyListUnits = (query: {
  projectId?: string
  isFlagged?: boolean
  hasTags?: boolean
  tagName?: Array<string>
  page?: number
  pageSize?: number
}) => {
  const api = useApi()
  const queryClient = useQueryClient()

  return () => {
    return queryClient.fetchQuery<PagedUnitSchema, ApiError>({
      queryKey: ['listUnits', query],
      queryFn: () => api.listUnits(query),
    })
  }
}

export const useUpdateUnit = ({ id }: { id: string }) => {
  const api = useApi()
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const params = { id, ...searchParamsToObject(searchParams) }

  const mutation = useMutation<UnitSchema, ApiError, UnitUpdateSchema>({
    mutationKey: ['updateUnit', { id }],
    mutationFn: (data) => api.updateUnit({ id, requestBody: data }),
    onSuccess: (response) => {
      queryClient.setQueryData(['getUnit', params], (data: UnitDetailSchema) => {
        return {
          ...data,
          unit: response,
        }
      })
      queryClient.invalidateQueries({ queryKey: ['listUnits', { projectId: response.project.id }] })
    },
    onError: () => {}, // overwrite default error handling
  })

  return mutation
}

export const useCreateTag = ({ unitId }: { unitId: string }) => {
  const api = useApi()
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const params = { id: unitId, ...searchParamsToObject(searchParams) }

  const mutation = useMutation<UnitSchema, ApiError, UnitTagCreateSchema>({
    mutationKey: ['createTag', { unitId }],
    mutationFn: (data) => api.createTag({ id: unitId, requestBody: data }),
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ['getVote'] })

      queryClient.setQueryData(['getUnit', params], (data: UnitDetailSchema) => {
        return {
          ...data,
          unit: response,
        }
      })
      queryClient.refetchQueries({
        queryKey: ['getFrameworkStats', { id: response.project.framework.id }],
        type: 'active',
        exact: true,
      })
    },
  })

  return mutation
}

export const useBulkUpdateTags = ({ unitId }: { unitId: string }) => {
  const api = useApi()
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const params = { id: unitId, ...searchParamsToObject(searchParams) }

  return useMutation<UnitSchema, ApiError, UnitTagUpdateSchema[]>({
    mutationKey: ['useBulkUpdateTags', { unitId }],
    mutationFn: async (data) => api.bulkUpdateTags({ id: unitId, requestBody: data }),
    onSuccess: (response) => {
      queryClient.setQueryData(['getUnit', params], (data: UnitDetailSchema) => {
        return {
          ...data,
          unit: response,
        }
      })
    },
  })
}

export const useDeleteUnitTag = ({
  unitId,
  evidenceSources = [],
}: {
  unitId: string
  evidenceSources: string[]
}) => {
  const api = useApi()
  const queryClient = useQueryClient()
  const [searchParams] = useSearchParams()
  const params = { id: unitId, ...searchParamsToObject(searchParams) }

  return useMutation<void, ApiError, TagDeleteSchema & { id: string }>({
    mutationKey: ['deleteTag', { unitId }],
    mutationFn: ({ id, feedback }) =>
      api.deleteTag({ id, requestBody: { feedback, evidenceSources } }),
    onSuccess: (response, { id }) => {
      queryClient.setQueryData<UnitDetailSchema | undefined>(['getUnit', params], (data) => {
        if (!data) return data

        queryClient.invalidateQueries({ queryKey: ['getVote'] })

        queryClient.refetchQueries({
          queryKey: ['getFrameworkStats', { id: data.unit.project.framework.id }],
          type: 'active',
          exact: true,
        })

        queryClient.invalidateQueries({ queryKey: ['listComments', { objectId: unitId }] })
        return {
          ...data,
          unit: {
            ...data.unit,
            tags: data.unit.tags?.filter((tag) => tag.id !== id),
          },
        }
      })
    },
  })
}
