import { Button, IconButton } from '@components/button'
import { Tooltip } from '@components/tooltip'
import { Typography } from '@components/typography'
import { Close } from '@mui/icons-material'
import { Box, StyleProps, alpha } from '@mui/material'
import { ToastContainer, Slide, toast as toastify } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const styles: StyleProps = {
  root: {
    '& .Toastify__toast-container': {
      zIndex: 300,
      borderRadius: 1,
      padding: 0,
      minWidth: 400,
      maxWidth: 400,
      '& .Toastify__toast': {
        p: 3,
        backgroundColor: 'N800',
        borderRadius: 1,
        color: 'N000',
        boxShadow: (theme) => `
          0 5px 5px -3px ${alpha(theme.palette.N1000, 0.2)},
          0 3px 14px 2px ${alpha(theme.palette.N1000, 0.12)},
          0 8px 10px 1px ${alpha(theme.palette.N1000, 0.14)}`,
        '&.Toastify__toast--success': {
          backgroundColor: 'G050',
          color: 'N800',
        },
        '&.Toastify__toast--error': {
          backgroundColor: 'R050',
          color: 'N800',
        },
        '& .Toastify__toast-body': {
          p: 0,
        },
      },
    },
  },
  toaster: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
    width: '100%',
    '& .ToastMessage-closeBtn': {
      position: 'absolute',
      top: 12,
      right: 12,
      color: 'N000',
    },
    '& .MuiTypography-root': {
      color: 'N000',
    },
    '&.ToastMessage-success, &.ToastMessage-error': {
      '& .MuiTypography-root': {
        color: 'N800',
      },
      '& .ToastMessage-closeBtn': {
        color: 'N500',
      },
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}

export const Toaster = () => {
  return (
    <Box sx={styles.root}>
      <ToastContainer
        position='bottom-right'
        icon={false}
        autoClose={3000}
        closeButton={false}
        limit={3}
        hideProgressBar={true}
        draggable={false}
        closeOnClick={false}
        pauseOnHover
        transition={Slide}
      />
    </Box>
  )
}

export type ToastMessageProps = {
  heading: string
  message?: string
  type?: string
  showCancel?: boolean
  showButton?: boolean
  buttonText?: string
  closeToast?: () => void
}

export const ToastMessage = ({
  heading,
  message,
  type,
  showCancel = true,
  showButton = true,
  buttonText = 'OK',
  closeToast,
}: ToastMessageProps) => {
  return (
    <Box sx={styles.toaster} className={`ToastMessage-${type}`}>
      {showCancel && (
        <Tooltip title="Close" placement="left" leaveTouchDelay={0} enterTouchDelay={0}>
          <IconButton
            className="ToastMessage-closeBtn"
            color="primary"
            aria-label="close"
            onClick={() => closeToast?.()}
          >
            <Close fontSize="small" />
          </IconButton>
        </Tooltip>
      )}

      <Typography variant="h4">{heading}</Typography>
      <Typography variant="body1">{message}</Typography>

      {showButton && (
        <Box sx={styles.actions}>
          <Button
            variant="contained"
            color="primary"
            aria-label="toaster button"
            onClick={() => closeToast?.()}
          >
            {buttonText}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export const toast = {
  notify: (options: Omit<ToastMessageProps, 'type' | 'closeToast'>) => {
    return toastify(({ closeToast }) => <ToastMessage closeToast={closeToast} {...options} />, {
      toastId: 'toast',
    })
  },
  success: (options: Omit<ToastMessageProps, 'type' | 'closeToast'>) => {
    return toastify.success(
      ({ closeToast }) => <ToastMessage type="success" closeToast={closeToast} {...options} />,
      {
        toastId: 'toast-success',
      }
    )
  },
  error: (options: Omit<ToastMessageProps, 'type' | 'closeToast'>) => {
    return toastify.error(
      ({ closeToast }) => <ToastMessage type="error" closeToast={closeToast} {...options} />,
      {
        toastId: 'toast-error',
      }
    )
  },
  promise: (
    promise: Promise<object>,
    options: Record<'pending' | 'success' | 'error', Omit<ToastMessageProps, 'type' | 'closeToast'>>
  ) => {
    return toastify.promise(
      promise,
      {
        pending: {
          render: ({ closeToast }) => <ToastMessage closeToast={closeToast} {...options.pending} />,
        },
        success: {
          render: ({ closeToast }) => (
            <ToastMessage type="success" closeToast={closeToast} {...options.success} />
          ),
        },
        error: {
          render: ({ closeToast }) => (
            <ToastMessage type="error" closeToast={closeToast} {...options.error} />
          ),
        },
      },
      {
        toastId: 'toast-promise',
      }
    )
  },
}
