import {
  Pagination as MuiPagination,
  PaginationProps as MuiPaginationProps,
  StyleProps,
} from '@mui/material';

type PaginationProps = Omit<MuiPaginationProps, 'shape' | 'sx' | 'style'>;

const styles: StyleProps = {
  root: {
    ul: {
      '& .MuiPaginationItem-root': {
        minWidth: 20,
        height: 24,
        '& .Mui-selected': {
          bgcolor: 'N500',
        },
        borderRadius: '3px',
      },
    },
  },
};
export const Pagination = (props: PaginationProps) => {
  return <MuiPagination {...props} shape="rounded" sx={styles.root} />;
};
