import { Avatar } from '@components/avatar'
import { Typography } from '@components/typography'
import { Box, StyleProps, alpha, styled } from '@mui/material'
import { ReactNode } from 'react'

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    p: 3,
    pr: 4,
    gap: 2,
    bgcolor: 'N000',
    boxShadow: (theme) =>
      `0 1px 1px 0 ${alpha(theme.palette.N900, 0.25)}, 0 0 1px 0 ${alpha(
        theme.palette.N900,
        0.31
      )}`,
    borderRadius: 0.5,
  },
  user: {
    flex: '0 0 10%',
    display: 'flex',
    alignItems: 'center',
    gap: 2,
  },
  content: {
    flex: '1 1 auto',
    minWidth: 0,
    maxWidth: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  menu: {
    flex: '0 0 auto',
    display: 'flex',
  },
} satisfies StyleProps

type ActivityRootProps = {
  children: ReactNode
}

const ActivityRoot = ({ children }: ActivityRootProps) => {
  return <Box sx={styles.root}>{children}</Box>
}

const UserName = styled(Typography)(({ theme }) => ({
  width: 170,
  maxWidth: 170,
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: theme.palette.N500,
}))

type ActivityUserProps = {
  by: {
    id: string
    firstName: string
    lastName: string
    email: string
    avatar: string
  }
}

const ActivityUser = ({ by }: ActivityUserProps) => {
  const userName = `${by.firstName} ${by.lastName}`.trim()

  return (
    <Box sx={styles.user} aria-label="user profile">
      <Avatar bgcolor="G500" label={userName} size={40} aria-label="user avatar" src={by.avatar}/>
      <UserName variant="body1">{userName}</UserName>
    </Box>
  )
}

type ActivityContentProps = {
  children: ReactNode
}

const ActivityContent = ({ children }: ActivityContentProps) => {
  return <Box sx={styles.content}>{children}</Box>
}

export const ActivityLayout = {
  Root: ActivityRoot,
  User: ActivityUser,
  Content: ActivityContent,
}
