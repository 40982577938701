/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Frameworks {
  AB_BLUEPRINT = 'AB Blueprint',
  ABPMR = 'ABPMR',
  ASTRONOMY_DOMAIN = 'Astronomy Domain',
  CELPIP_R01 = 'CELPIP R01',
  ELA_LITERACY = 'ELA Literacy',
  ENCORE_BLOOM = 'Encore Bloom',
  ESS_COMPLEXITY = 'ESS Complexity',
  NCLEX = 'NCLEX',
  NOCTI_COMPETENCY = 'NOCTI Competency',
  PSYCH_APA_SKILLS = 'Psych APA Skills',
  PSYCH_LEARNING_OBJECTIVES = 'Psych Learning Objectives',
  QSEN_TASK = 'QSEN Task',
  READING_SKILL = 'Reading Skill',
}
