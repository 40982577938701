import { FrameworkSchema, TagSchema } from '@core/api/generated'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export type Offsets = { start: number; end: number }

export type Source = {
  origin?: string
  offsets?: Offsets[]
  id?: string
}
type NewEvidence = {
  id: string | null
  source: Source[]
}

export type EvidenceDisplay = {
  open: boolean
  newEvidence: NewEvidence | null
  deleteEvidence: Partial<NewEvidence> | null
  highlight: {
    id: string | null
    pending: boolean | null
    highlighted: boolean | null
    source?: Source[]
  }
}

type State = {
  activeTag: TagSchema | null
  rationalesCleaned: string[],
  tags: TagSchema[]
  evidence: EvidenceDisplay
}

function evidenceInitialState(): EvidenceDisplay {
  return {
    open: false,
    newEvidence: null,
    deleteEvidence: null,
    highlight: {
      id: null,
      pending: null,
      highlighted: null,
    },
  }
}

export function getInitialState(): State {
  return {
    tags: [],
    rationalesCleaned: [],
    evidence: evidenceInitialState(),
    activeTag: null,
  }
}

export const slice = createSlice({
  name: 'reviewDetail',
  initialState: getInitialState(),
  reducers: {
    reset: () => getInitialState(),
    setTags(state: State, action: PayloadAction<TagSchema[]>) {
      state.tags = action.payload

      if (state.activeTag) {
        state.activeTag = state.tags.find((tag) => tag.id === state.activeTag?.id) ?? null
      }
    },
    setRationalesCleaned(state: State, action: PayloadAction<string>) {
      state.rationalesCleaned = [...state.rationalesCleaned, action.payload]
    },
    setOpenEvidence(state: State, action: PayloadAction<boolean>) {
      state.evidence.open = action.payload
    },
    setNewEvidence(state: State, action: PayloadAction<NewEvidence | null>) {
      state.evidence.newEvidence = action.payload
    },
    triggerHighlightEvidence(
      state: State,
      action: PayloadAction<{
        id: string
        source: Source[]
      }>
    ) {
      state.evidence.highlight = {
        id: action.payload.id,
        pending: true,
        highlighted: false,
        source: action.payload.source,
      }
    },
    triggerUnHighlightEvidence(state: State, action: PayloadAction<string>) {
      state.evidence.highlight = {
        id: action.payload,
        pending: true,
        highlighted: true,
        source: state.evidence.highlight.source,
      }
    },
    triggerDeleteEvidence(state, action: PayloadAction<Partial<NewEvidence>>) {
      state.evidence.deleteEvidence = action.payload
    },
    highlightEvidence(state: State, action: PayloadAction<boolean>) {
      state.evidence.highlight.highlighted = action.payload
      state.evidence.highlight.pending = false
    },
    deleteEvidence(state: State, action: PayloadAction<string>) {
      state.evidence.deleteEvidence = null

      if (state.evidence.highlight.id === action.payload) {
        state.evidence.highlight = {
          id: null,
          pending: null,
          highlighted: null,
        }
      }
    },
    cleanEvidenceHighlight(state: State) {
      state.evidence.highlight = {
        id: null,
        pending: null,
        highlighted: null,
      }
    },
    setActiveTag(state: State, action: PayloadAction<TagSchema | null>) {
      state.activeTag = action.payload
      state.evidence = evidenceInitialState()
    },
    setActiveTagFromFramework(state: State, action: PayloadAction<FrameworkSchema>) {
      state.activeTag = state.tags.find((tag) => tag.framework.id === action.payload.id) ?? null
    },
  },
})

export default slice.reducer

export const { actions } = slice
