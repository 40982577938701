import { useAuth0 } from '@auth0/auth0-react'
import { ApiClient } from '../generated'
import { ApiRequestOptions } from '../generated/core/ApiRequestOptions'
import { FTC_API, IS_STORYBOOK } from '@core/config'

type Resolver<T> = (options: ApiRequestOptions) => Promise<T>

export const useApi = () => {
  const { getAccessTokenSilently } = useAuth0()

  if (IS_STORYBOOK) return new ApiClient({ BASE: FTC_API }).default

  const token = getAccessTokenSilently()
  return new ApiClient({ BASE: FTC_API, TOKEN: token as unknown as Resolver<string> }).default
}
