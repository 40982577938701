import { Typography } from '@components/typography'
import { Box, StyleProps } from '@mui/material'
import { URLSearchParamsInit, useSearchParams } from 'react-router-dom'
import { Drawer, Switch } from '@components'

const styles: StyleProps = {
  filter: {
    display: 'flex',
    mb: 3,
    mr: 2,
  },
  filterContainer: {
    display: 'flex',
    flexDirection: 'column',
    px: 3,
    width: 396,
  },
}
type FilterDrawerProps = {
  open: boolean
  handleClose: () => void
}

export const FilterDrawer = (props: FilterDrawerProps) => {
  const { open, handleClose } = props
  const [searchParams, setSearchParams] = useSearchParams()
  const query = Object.fromEntries(searchParams)

  const onChange = (data: Record<string, string>) => {
    const newQuery = { ...data, page: 1 } as Record<string, string | number>

    const key = Object.keys(data)[0]
    if (Object.hasOwn(query, key)) {
      delete newQuery[key]
    }
    setSearchParams(newQuery as URLSearchParamsInit)
  }

  return (
    <Drawer open={open} onClose={handleClose} anchor="left">
      <Box sx={styles.filterContainer}>
        <Box>
          <Typography variant="h4">Filter results</Typography>

          <Box pt={5}>
            <Box sx={styles.filter}>
              <Switch
                label="Items with no tags applied"
                onClick={() => {
                  onChange({ hasTags: 'false' })
                }}
                checked={searchParams.has('hasTags')}
              />
            </Box>
            <Box sx={styles.filter}>
              <Switch
                label="Items Flagged for Review"
                onClick={() => {
                  onChange({ isFlagged: 'true' })
                }}
                checked={searchParams.has('isFlagged')}
              />
            </Box>
            <Box sx={styles.filter}>
              <Switch
                label="Items with comments"
                onClick={() => {
                  onChange({ hasComments: 'true' })
                }}
                checked={searchParams.has('hasComments')}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Drawer>
  )
}
