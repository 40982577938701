import {
  Button as MuiButton,
  ButtonProps as MuiButtonProps,
  StyleProps,
  alpha,
} from '@mui/material'
import { forwardRef } from 'react'

export type ButtonProps = Omit<
  MuiButtonProps,
  | 'action'
  | 'disableElevation'
  | 'disableFocusRipple'
  | 'focusVisibleClassName'
  | 'touchRippleRef'
  | 'TouchRippleProps'
  | 'sx'
  | 'style'
>

const styles: StyleProps = {
  button: {
    boxShadow: 'none',
    '&.MuiButton-root': {
      px: 1,
      py: 0.5,
      height: 32,
      '&.MuiButton-text, &.MuiButton-containedSecondary': {
        '&:hover': {
          background: (theme) => `${alpha(theme.palette.N200, 0.16)}`,
        },
      },
      '&.MuiButton-containedPrimary': {
        bgcolor: 'N500',
        '&:hover': {
          bgcolor: 'N800',
          boxShadow: (theme) =>
            `0 0 1px 0 ${alpha(theme.palette.N900, 0.25)}, 0 4px 8px -2px ${alpha(
              theme.palette.N900,
              0.31
            )}`,
        },
      },
      '&.MuiButton-containedSecondary': {
        background: (theme) => `${alpha(theme.palette.N900, 0.04)}`,
        color: 'N500',
        '&:hover': {
          boxShadow: 'none',
        },
      },
      '&.MuiButton-containedPrimary, &.MuiButton-containedSecondary': {
        '&:disabled': {
          background: (theme) => `${alpha(theme.palette.N900, 0.04)}`,
          color: 'N070',
        },
      },
    },
  },
}

export const Button: React.FC<ButtonProps> = forwardRef((props: ButtonProps, ref) => {
  return <MuiButton {...props} sx={styles.button} ref={ref} />
})
