import {
  ActivityTagAdded,
  ActivityTagDeleted,
  ActivityTagReordered,
  ActivityUnitFinalized,
  ActivityUnitReopened,
  ActivityUnitFlagged,
  ActivityUnitUnflagged,
  ActivityCommentAdded,
  ActivityEvidenceAdded,
} from './actions'
import { Snapshot } from './types'

export type ActivityProps = {
  snapshot: Snapshot
}

export const Activity = ({ snapshot }: ActivityProps) => {
  let Component

  switch (snapshot.action) {
    case 'unit_finalized':
      Component = ActivityUnitFinalized
      break
    case 'unit_reopened':
      Component = ActivityUnitReopened
      break
    case 'unit_flagged':
      Component = ActivityUnitFlagged
      break
    case 'unit_unflagged':
      Component = ActivityUnitUnflagged
      break
    case 'tag_reordered':
      Component = ActivityTagReordered
      break
    case 'tag_deleted':
      Component = ActivityTagDeleted
      break
    case 'tag_added':
      Component = ActivityTagAdded
      break
    case 'evidence_added':
      Component = ActivityEvidenceAdded
      break
    case 'comment_added':
      Component = ActivityCommentAdded
      break
  }

  return Component ? <Component snapshot={snapshot} /> : null
}
