import { Chip } from '@components/chip'
import { colorScale } from '@core/styles/colors'
import { styled } from '@mui/material'
import { Link } from 'react-router-dom'

const CHIP_COLORS = colorScale(['purple', 'teal'])

export const WrapLink = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  color: theme.palette.B300,
  textDecoration: 'none',
}))

type TagDisplayProps = {
  index: number
  tag: {
    id: string
    framework: {
      shortName: string
    }
  }
}

export const TagDisplay = ({ index, tag }: TagDisplayProps) => {
  return (
    <Chip
      key={tag.id}
      aria-label="tag"
      size="small"
      label={tag.framework.shortName}
      bgcolor={CHIP_COLORS[index] as string}
      textColor="N000"
      maxWidth={170}
    />
  )
}
