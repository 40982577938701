import { DownloadDialog, File, Menu, MenuButton, MenuItem, Skeleton, Typography } from '@components'
import { Modal } from '@components/modal'
import { useLazyExportProject, useLazyListUnits, useProject } from '@core/api/hooks'
import { useToggle } from '@core/hooks'
import { Box, StyleProps, styled } from '@mui/material'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

const styles = {
  exportModal: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
} satisfies StyleProps

const ExportButton = styled(MenuButton)(({ theme }) => ({
  width: 250,
  backgroundColor: theme.palette.N000,
  fontSize: 21,
  fontWeight: 500,
  color: theme.palette.N500,
  '&.MuiButton-containedInherit': {
    height: 48,
  },
  '&:hover': {
    outline: `2px solid ${theme.palette.B100}`,
  },
}))

const ExportMenu = styled(Menu)(() => ({
  '.MuiPaper-root': { minWidth: 250, paddingTop: 0 },
}))

export const ResultsExport = () => {
  const { projectId } = useParams() as { projectId: string }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [loadingFile, setLoadingFile] = useToggle()
  const [files, setFiles] = useState<File[]>(
    () => JSON.parse(sessionStorage.getItem('downloadList') as string) || []
  )
  const [exportModal, showExportModal] = useToggle()

  const { data, isLoading } = useProject(projectId)
  const { exportProject } = useLazyExportProject()
  const listUnits = useLazyListUnits({ projectId, isFlagged: true, pageSize: 1 })

  const onConfirmExport = async () => {
    const downloadFile = {
      filename: `${data?.title}.xlsx`,
      blob: '',
      contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
    }
    setFiles([...files, downloadFile])
    setLoadingFile()
    showExportModal()

    try {
      const response = await exportProject(projectId)
      downloadFile.blob = response

      setFiles([...files, downloadFile])
      sessionStorage.setItem('downloadList', JSON.stringify([...files, downloadFile]))
    } catch (error) {
      setFiles([])
    }

    setLoadingFile()
    setAnchorEl(null)
  }

  const exportSurpass = async () => {
    const units = await listUnits()

    if (units.count > 0) {
      showExportModal()
    } else {
      onConfirmExport()
    }
  }

  const handleClearDownload = () => {
    setFiles([])
    sessionStorage.removeItem('downloadList')
  }

  return (
    <Box>
      {isLoading ? (
        <Skeleton width={250} height={48} variant="rounded" />
      ) : (
        <>
          <ExportButton
            variant="contained"
            color="inherit"
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            Export
          </ExportButton>

          <ExportMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            disableScrollLock
          >
            <MenuItem onClick={exportSurpass} disableRipple>
              Surpass
            </MenuItem>
          </ExportMenu>
        </>
      )}

      <DownloadDialog
        loading={loadingFile}
        onClear={handleClearDownload}
        onDownload={(file) => file.click()}
        files={files}
      />

      <Modal
        open={exportModal}
        onConfirm={onConfirmExport}
        onCancel={showExportModal}
        onClose={showExportModal}
      >
        <Box sx={styles.exportModal}>
          <Typography variant="h4">Some of your items are still flagged!</Typography>
          <Typography variant="body1" fontWeight={400} color="N400">
            Do you want to continue with the item export?
          </Typography>
        </Box>
      </Modal>
    </Box>
  )
}
