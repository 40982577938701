import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { REVIEW_ROUTE, PROJECTS_ROUTE, RESULTS_ROUTE } from '@core/constants/routes'
import { Tabs, Tab, StyleProps } from '@mui/material'
import { SyntheticEvent } from 'react'

export const getCurrentActiveRoute = (pathname: string) => {
  if (pathname.startsWith('/review')) {
    return REVIEW_ROUTE
  } else if (pathname.startsWith('/results')) {
    return RESULTS_ROUTE
  }

  return PROJECTS_ROUTE
}

const styles: StyleProps = {
  tab: {
    color: 'N200',
    '&.Mui-selected': {
      color: 'N900',
    },
  },
  tabIndicator: {
    bgcolor: 'N900',
  },
}

const CatalogTabs = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { projectId } = useParams()

  const value = getCurrentActiveRoute(location.pathname)

  const handleChange = (_: SyntheticEvent<Element, Event>, route: string) => {
    if ([REVIEW_ROUTE, RESULTS_ROUTE].includes(route)) {
      navigate(generatePath(route, { projectId: projectId as string }))
    } else {
      navigate(route)
    }
  }

  return (
    <Tabs
      variant="standard"
      indicatorColor="primary"
      value={value}
      onChange={handleChange}
      aria-label="nav tabs"
      TabIndicatorProps={{
        sx: styles.tabIndicator,
      }}
    >
      <Tab label="Projects" value={PROJECTS_ROUTE} sx={styles.tab} />

      <Tab label="Review" value={REVIEW_ROUTE} sx={styles.tab} disabled={!projectId} />

      <Tab label="Results" value={RESULTS_ROUTE} sx={styles.tab} disabled={!projectId} />
    </Tabs>
  )
}

export default CatalogTabs
