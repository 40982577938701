import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type State = {
  selectedUnit: string
}

export function getInitialState(): State {
  return {
    selectedUnit: '',
  }
}
export const slice = createSlice({
  name: 'review',
  initialState: getInitialState(),
  reducers: {
    set(state: State, action: PayloadAction<Partial<State>>) {
      Object.assign(state, action.payload)
    },
  },
})

export default slice.reducer

export const { actions } = slice
